<template>
	<section class="flex_10 animateSection" 
					 :data-fcpCount="flexCount"
					 :style="customCSS"
					 ref="thisSection">
		<div class="widthLimit contentContainer"
				 :class="[extraVars.flexiColor.content, flexVar.type]">
			<div class="content wysiwyg"
					 v-html="flexVar.content">
			</div>
			<div v-if="flexVar.type === 'headline'" 
					 class="arrowDown">
				<svg viewBox="0 0 28.989 20.398" xmlns="http://www.w3.org/2000/svg">
					<g transform="translate(44.531 -41.775) rotate(90)">
						<path transform="translate(-187.99 -228.12)" d="M239.817,272.154h-9.131a.414.414,0,0,1-.341-.648l8.668-12.958a1.307,1.307,0,0,0,0-1.479l-8.671-12.257a.414.414,0,0,1,.341-.647h8.772a1.307,1.307,0,0,1,1.076.565l8.86,12.208a1.307,1.307,0,0,1,.006,1.477l-8.5,13.166A1.307,1.307,0,0,1,239.817,272.154Z" stroke-miterlimit="10"/>
					</g>
				</svg>
			</div>
		</div>
		
	</section>
</template>

<script>

import * as globalFunctions from '@/js/globalFunctions.js';
	
export default {
  name: 'flex_10',
  props: {
		flexVar: Object,
		extraVars: Object,
		flexCount: Number,
  },
  data(){
    return {
			parentFlexContainer: '',
			parallaxObjs: [],
			scrollThrottleTimer: undefined,
		}
	},
	computed: {
		customCSS: function() {
			if ( this.extraVars.positionalVars.screenType == 'mobile') {
				return this.flexVar.custom_css_group.custom_css_mobile;
			} else {
				return this.flexVar.custom_css_group.custom_css_desktop;
			}
		},
	},
	mounted(){	
		//set flexContainer to be used within scope of this component
		this.parentFlexContainer = globalFunctions.closestParent(this.$refs.thisSection, '.flexContainer');

	},
  methods: {
		throttleScroll (newPos, oldPos, delay) {
			// If setTimeout is already scheduled, no need to do anything
			if (this.scrollThrottleTimer) {
				return;
			}

			// Schedule a setTimeout after delay seconds
			this.scrollThrottleTimer = setTimeout(() => {
				//add throttled code / function calls here
				
				this.scrollThrottleTimer = undefined;
			}, delay);
		},
	},
	watch: {
		'extraVars.flexScrollPos.pos': function(newPos, oldPos) {
			//add unthrottled scroll code here

			//run throttled scroll code
			var delay = 500;
			this.throttleScroll(newPos, oldPos, delay);
		},
	},
  created () {
  },
  destroyed () {
  }
}
</script>

<style>	
</style>