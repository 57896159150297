<template>
	<section class="flex_03 animateSection" 
					 :data-fcpCount="flexCount"
					 ref="thisSection">
		
		<div class="widthLimit sectionContainer"
				 v-bind:class="[ flexVar.position ]"
				 :style="customCSS"
				 ref="sectionContainer">			
			<div class="scrollContainer accelerateGPU"
					 ref="scrollContainer">

				<div class="laptopContainer accelerateGPU"
						 ref="laptopContainer">
					<div class="laptopScreen accelerateGPU"
							 ref="laptopScreen">
						<img src="@/icons/flex03_laptopScreen.png"
								 v-bind:style="screenImgStyle">
						<img class="blackScreen" src="@/icons/flex03_laptopScreenBlack.png"
								 v-bind:style="screenImgStyle">
					</div>
					<div class="laptopCoverContainer accelerateGPU"
							 ref="laptopCoverContainer">
						<div class="laptopCover"
								 ref="laptopCover"
								 v-bind:style="laptopCoverStyle">
							<img src="@/icons/flex03_laptopCover.png">
						</div>
						<div class="laptopBottom accelerateGPU"
								 ref="laptopBottom">
							<img src="@/icons/flex03_laptopBottom.png">
						</div>
					</div>
				</div>
			</div>
			
			<div class="imageContainer accelerateGPU" >
				<svg enable-background="new 0 0 1000 10000" viewBox="0 0 1000 10000" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="laptopMask accelerateGPU" ref="laptopMask">
					 <mask id="laptopImgMask">
							<rect fill="#fff" height="100%" width="100%"></rect>
							<path d="M 100 5060 H 900 V 5580 H 100 Z"></path>
					 </mask>
					 <rect :fill="extraVars.flexiColor.bg" height="100%" mask="url(#laptopImgMask)" width="100%"></rect>
				</svg>
				<img v-bind:src="flexVar.image.url"
						 ref="laptopImage">
			</div>
		</div>
	</section>
</template>

<script>

import * as globalFunctions from '@/js/globalFunctions.js';
	
export default {
  name: 'flex_03',
  props: {
		flexVar: Object,
		flexCount: Number,
		extraVars: Object,
  },
  data(){
    return {
			parentFlexContainer: '',
			parallaxObjs: [],
			scrollThrottleTimer: undefined,
			screenImgStyle: {
				height: 0,
				top: 0,
			},
			laptopCoverStyle: {
				transform: 'translateY(0)',
			},
		}
	},
	computed: {
		customCSS: function() {
			if ( this.extraVars.positionalVars.screenType == 'mobile') {
				return this.flexVar.custom_css_group.custom_css_mobile;
			} else {
				return this.flexVar.custom_css_group.custom_css_desktop;
			}
		},
	},
	mounted(){	
		//set flexContainer to be used within scope of this component
		this.parentFlexContainer = globalFunctions.closestParent(this.$refs.thisSection, '.flexContainer');
		
	},
  methods: {
		throttleScroll (newPos, oldPos, delay) {
			// If setTimeout is already scheduled, no need to do anything
			if (this.scrollThrottleTimer) {
				return;
			}

			// Schedule a setTimeout after delay seconds
			this.scrollThrottleTimer = setTimeout(() => {
				//add throttled code / function calls here
				
				this.setLaptopPos();
				
				this.scrollThrottleTimer = undefined;
			}, delay);
		},
		handleScroll (event) {			
      // Any code to be executed when the window is scrolled
			// Handle all parallax actions
			this.addParallax();
			
			// Handle laptop opening on scroll
			if ( this.parentFlexContainer.scrollTop >= this.parallaxObjs[1].startScroll	) {
				this.$refs.sectionContainer.classList.add("flipOpen");
				this.$refs.sectionContainer.classList.remove("flipClose");
				this.openLaptop();
			} else if ( this.parentFlexContainer.scrollTop < this.parallaxObjs[1].startScroll	) {
				this.closeLaptop();
				if ( this.$refs.sectionContainer.classList.contains("flipOpen") ) {
					this.$refs.sectionContainer.classList.add("flipClose");
					this.$refs.sectionContainer.classList.remove("flipOpen");
				}
			}

			this.setLaptopPos();
			
    },
		addParallax () {
			//laptop bottom
			var laptopBottom = this.$refs.laptopBottom;
			var laptopCoverContainer = this.$refs.laptopCoverContainer;
			var scrollContainer = this.$refs.scrollContainer;
			//calculates central starting point for laptop
			var flexContainerHeight = this.parentFlexContainer.offsetHeight;
			var laptopHeight = laptopBottom.offsetHeight;
			var offsetRatio = (flexContainerHeight -  laptopHeight) / (2 * flexContainerHeight);
			var startScroll00 = globalFunctions.parallaxStartStop(scrollContainer, offsetRatio).start;
			var cssStart00 = -1 * laptopBottom.offsetHeight * 0.891;
			
			//laptop cover
			this.parallaxObjs[0] = {
				startScroll: startScroll00,
				stopScroll: startScroll00 - cssStart00,
				element: $(laptopCoverContainer),
				cssProp: '-webkit-transform',
				multi: true,
				subprops: [
					{
						subprop: 'translateY',
						cssStart: 0,
						cssStop: -1 * cssStart00,
						cssUnit: 'px',
					},
				]
			};
			
			//laptop container
			var laptopContainer = this.$refs.laptopContainer;
			var laptopImage = this.$refs.laptopImage;
			//set spacing above and bottom image for scroll visibility, ratio to laptop size
			var laptopPadBottom = laptopBottom.offsetHeight * 0.2386;
			$(laptopImage)
				.css('padding-top', laptopBottom.offsetHeight * 0.0486)
				.css('padding-bottom', laptopPadBottom);
			var startScroll02 = startScroll00 - cssStart00;
			var stopScroll02 = startScroll02 + laptopImage.offsetHeight - laptopBottom.offsetHeight - (laptopPadBottom / 2);
			this.parallaxObjs[1] = {
				startScroll: startScroll02,
				stopScroll: stopScroll02,
				element: $(laptopContainer),
				cssProp: '-webkit-transform',
				multi: true,
				subprops: [
					{
						subprop: 'translateY',
						cssStart: 0,
						cssStop: stopScroll02 - startScroll02,
						cssUnit: 'px',
					},
				]
			};
			
			//laptopScreen
			var laptopScreen = this.$refs.laptopScreen;
			this.parallaxObjs[2] = {
				startScroll: startScroll00,
				stopScroll: startScroll00 - cssStart00,
				element: $(laptopScreen),
				cssProp: '-webkit-transform',
				multi: true,
				subprops: [
					{
						subprop: 'translateY',
						cssStart: cssStart00,
						cssStop: 0,
						cssUnit: 'px',
					},
				]
			};
			
			//laptopMask
			var laptopMask = this.$refs.laptopMask;
			var laptopStart = -0.5 * $(laptopMask).height() - laptopBottom.offsetHeight * 0.0486;
			this.parallaxObjs[3] = {
				startScroll: startScroll02,
				stopScroll: stopScroll02,
				element: $(laptopMask),
				cssProp: '-webkit-transform',
				multi: true,
				subprops: [
					{
						subprop: 'translateY',
						cssStart: laptopStart,
						cssStop: laptopStart + stopScroll02 - startScroll02,
						cssUnit: 'px',
					},
				]
			};
			
			
			globalFunctions.parallaxHandler(this.$refs.thisSection, this.parallaxObjs);
    },
		openLaptop() {
			var laptopCover = this.$refs.laptopCover;
			var laptopBottom = this.$refs.laptopBottom;
			
			this.screenImgStyle.height = laptopBottom.offsetHeight * 0.932 + 'px';
			this.screenImgStyle.top = 0;
			this.laptopCoverStyle.transform = 'translateY(' + laptopBottom.offsetHeight * -1 * 0.4 + 'px)';
//			$(laptopCover).css('top', laptopBottom.offsetHeight * -1 * 0.4 + 'px');

		},
		closeLaptop() {
			var laptopCover = this.$refs.laptopCover;
			var laptopBottom = this.$refs.laptopBottom;
			
			this.screenImgStyle.height = 0;
			this.screenImgStyle.top = laptopBottom.offsetHeight * 0.932 + 'px';
			this.laptopCoverStyle.transform = 'translateY(0)';
//			$(laptopCover).css('top', 0);
		},
		setLaptopPos () {
			var laptopBottom = this.$refs.laptopBottom;
			var laptopScreen = this.$refs.laptopScreen;
			var laptopContainer = this.$refs.laptopContainer;
			
			//set laptopBottom height relative to width
			$(laptopBottom).css('top', ($(laptopBottom).height() * -0.9218));
			
			//set laptopScreen position
			$(laptopScreen).css('height', laptopBottom.offsetHeight * 0.932 + 'px');
			
			//make visibile laptopContainer
			$(laptopContainer).css('opacity', 1);
			
			//initial placing
			this.addParallax();
		},
	},
	watch: {
		'extraVars.flexScrollPos.pos': function(newPos, oldPos) {
			//add unthrottled scroll code here
			// Handle all parallax actions
			this.addParallax();
			
			// Handle laptop opening on scroll
			if ( this.parentFlexContainer.scrollTop >= this.parallaxObjs[1].startScroll	) {
				this.$refs.sectionContainer.classList.add("flipOpen");
				this.$refs.sectionContainer.classList.remove("flipClose");
				this.openLaptop();
			} else if ( this.parentFlexContainer.scrollTop < this.parallaxObjs[1].startScroll	) {
				this.closeLaptop();
				if ( this.$refs.sectionContainer.classList.contains("flipOpen") ) {
					this.$refs.sectionContainer.classList.add("flipClose");
					this.$refs.sectionContainer.classList.remove("flipOpen");
				}
			}

			//run throttled scroll code
			var delay = 500;
			this.throttleScroll(newPos, oldPos, delay);
		},
		'extraVars.positionalVars.screenWidth': function(val) {
			//if screenWidth changes recalc laptopbottom top position
			this.setLaptopPos();
		},
		'extraVars.isTransitionClassActive':  {
			handler(val) {
				if (!val){
					// after transition, set laptopbottom height relative to width
					this.setLaptopPos();
				}
			},
			deep: true
		},
	},
  created () {
  },
  destroyed () {
  }
}
</script>

<style>	
</style>