<template>
	<footer class="siteFooter animateSection">
		<div class="footerContainer widthLimit">
			<div class="footerLogo">
				<svg id="ADEMIC_grey_FULL" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 132.802 25.87">
					<path id="ADEMIC_grey_A" d="M6583.54,272.949a.733.733,0,0,0,.663.42h2.291a.733.733,0,0,0,.669-1.038l-11.185-24.4a.737.737,0,0,0-.67-.429h-1.4a.734.734,0,0,0-.68.455l-10.058,24.4a.734.734,0,0,0,.682,1.012H6573.9a.736.736,0,0,0,.738-.734v-2.129a.736.736,0,0,0-.738-.733h-4.672a.734.734,0,0,1-.682-1.013l5.97-14.338a.174.174,0,0,1,.318-.007Z" transform="translate(-6563.112 -247.499)" />
					<path id="ADEMIC_grey_D" d="M6676.9,260.434a12.724,12.724,0,0,1-12.46,12.935H6659.5a.735.735,0,0,1-.736-.734v-1.706a.735.735,0,0,1,.736-.733h4.874a8.1,8.1,0,0,0,6.09-2.794,10.6,10.6,0,0,0,0-13.9,8.135,8.135,0,0,0-6.09-2.829H6659.5a.735.735,0,0,1-.736-.734v-1.706a.735.735,0,0,1,.736-.734h4.941A12.746,12.746,0,0,1,6676.9,260.434Z" transform="translate(-6630.915 -247.499)" />
					<path id="ADEMIC_grey_E" d="M6737.066,247.5h15.8a.735.735,0,0,1,.736.734v1.706a.735.735,0,0,1-.736.734h-11.961a.735.735,0,0,0-.737.734v6.19a.735.735,0,0,0,.737.734h9.5a.735.735,0,0,1,.737.734V260.8a.735.735,0,0,1-.737.734h-9.5a.735.735,0,0,0-.737.734v7.191a.736.736,0,0,0,.737.734h11.961a.735.735,0,0,1,.736.733v1.706a.735.735,0,0,1-.736.734h-15.8a.735.735,0,0,1-.737-.734v-24.4A.735.735,0,0,1,6737.066,247.5Z" transform="translate(-6685.896 -247.499)" />
					<path id="ADEMIC_grey_M" d="M6841.831,248.233v24.4a.736.736,0,0,1-.737.734h-2.37a.734.734,0,0,1-.735-.734V254.867a.2.2,0,0,0-.377-.08l-8.146,18.148a.736.736,0,0,1-.672.434h-1.241a.734.734,0,0,1-.663-.414l-11.846-24.4a.734.734,0,0,1,.662-1.053h2.989a.74.74,0,0,1,.671.428l8.479,18.457a.342.342,0,0,0,.624-.006l8.065-18.439a.738.738,0,0,1,.675-.441h3.885A.736.736,0,0,1,6841.831,248.233Zm-26.559,13.479,2.4.009a.734.734,0,0,1,.732.734v10.181a.734.734,0,0,1-.735.734h-2.4a.735.735,0,0,1-.736-.734V262.445A.734.734,0,0,1,6815.272,261.711Z" transform="translate(-6741.33 -247.499)" />
					<path id="ADEMIC_grey_I" d="M6928.224,272.635v-24.4a.735.735,0,0,1,.736-.734h2.369a.734.734,0,0,1,.735.734v24.4a.734.734,0,0,1-.735.734h-2.369A.735.735,0,0,1,6928.224,272.635Z" transform="translate(-6821.918 -247.499)" />
					<path id="ADEMIC_grey_C" d="M6956.936,260.434A12.724,12.724,0,0,1,6969.4,247.5h4.94a.736.736,0,0,1,.737.734v1.706a.735.735,0,0,1-.737.734h-4.872a8.1,8.1,0,0,0-6.091,2.794,10.6,10.6,0,0,0,0,13.9,8.133,8.133,0,0,0,6.091,2.829h4.872a.735.735,0,0,1,.737.733v1.706a.735.735,0,0,1-.737.734h-4.94A12.745,12.745,0,0,1,6956.936,260.434Z" transform="translate(-6842.271 -247.499)" />
				</svg>
				<div class="linkedIn">
					<span>Catch me on </span>
					<a href="https://www.linkedin.com/in/michael-goh-38b07211/" target="_blank"><img src="@/icons/linkedIn_logo.png"></a>
				</div>
				<div class="copyright">© 2023 ADEMIC Ltd. All rights reserved.</div>
				<div class="designer">Designed by <a href="https://vangohcreative.com/" target="_blank">VanGoh Creative</a></div>
			</div>
			<div class="footerAnchors">
				<router-link
					v-for="anchor in extraVars.anchorPoints"
					class="anchorLink"
					v-bind:to="'?anchor=' + anchor.anchorName"
					v-on:click.native="anchorClick();">
					{{anchor.anchorTitle}}
				</router-link>
			</div>
			
			<div class="footerMenu">
				
				<ul v-if="extraVars.primaryMenu.length"
						class="topPageMenu">
					<li class="menu-item" v-for="menuItem in extraVars.primaryMenu">
						<!--If not a parent / valid link-->
						<router-link 
							v-if="menuItem.url"
							:data-menuID="menuItem.ID" 
							:data-postID="menuItem.object_id"
							:to="menuItem.url">
							{{ menuItem.title }}
						</router-link>

						<!--If a parent-->
						<div v-else class="parent"
							:data-menuID="menuItem.id">
							{{ menuItem.title }}

							<router-link
								v-for="childItem in menuItem.children"
								class="child"
								:data-menuID="childItem.ID" 
								:data-postID="childItem.object_id"
								:to="childItem.url"
								ref="childMenuItem">{{ childItem.title }} ></router-link>
						</div>

					</li>
				</ul>	
			</div>
		</div>
	</footer>
</template>

<script>
import * as globalFunctions from '@/js/globalFunctions.js';
	
export default {
  name: 'footerHandler',
  components: {
  },
  data(){
		return {
		}
  },
  props: {
		extraVars: Object,
  },
  methods: {
		anchorClick: function () {
			//scroll down to anchor point when clicked
			var flexcontainer = document.querySelector(".flexContainer"); //always first flexcontainer
			globalFunctions.scrollAnchor(flexcontainer);
		},
  },
	watch: {
	},
	mounted() {
//		console.log(this.extraVars);
	},
	beforeUpdate() {
	}
		
}
	
</script>