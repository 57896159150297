<template>
	<section class="flex_02 animateSection" 
					 :data-fcpCount="flexCount"
					 ref="thisSection">
		
		<div class="widthLimit sectionContainer"
				 :style="customCSS">
			
			<div class="phoneContainer"
					 ref="phoneContainer">
				<div class="phoneFront accelerateGPU">
					<svg enable-background="new 0 0 1041 2136" viewBox="0 0 1041 2136" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="phoneMask">
						<mask id="thisMask">
							<rect fill="#fff" height="100%" width="100%"/>
							<path d="m40.2 92.9v1941.9s18 68.7 75.2 68.7 801 0 801 0 73.6-14.7 73.6-86.6 0-1924 0-1924-7.6-82.3-80.1-82.3-771.5 0-771.5 0-75.9-9.8-98.2 82.3z"/>
						</mask>
						<rect :fill="extraVars.flexiColor.bg" height="100%" mask="url(#thisMask)" width="100%"/>
					</svg>
					<img class="phoneFrame" src="@/icons/flex02_phoneFront.png" 
							 ref="phoneFrame" @load="defineMobileHeight()">
					<div class="imageContainer"
							 ref="imageContainer">
						<img class="phoneHeader" 
								 v-bind:src="flexVar.phone_header.url"
								 ref="phoneHeader">
						<img class="phoneScroll accelerateGPU" 
								 v-bind:src="flexVar.image.url"
								 ref="phoneScroll">
					</div>
				</div>
				<div class="phoneSide">
					<img class="phoneFrame" src="@/icons/flex02_phoneSide.png">
				</div>
				<div class="phoneBack">
					<img class="phoneFrame" src="@/icons/flex02_phoneBack.png">
				</div>
			</div>

	</div>
		
<!--
	<pre>{{ flexVar }}</pre>
-->
		
	</section>
</template>

<script>

import * as globalFunctions from '@/js/globalFunctions.js';
	
export default {
  name: 'flex_02',
  props: {
		flexVar: Object,
		extraVars: Object,
		flexCount: Number,
  },
  data(){
    return {
			parentFlexContainer: '',
			parallaxObjs: [],
		}
	},
	computed: {
		customCSS: function() {
			if ( this.extraVars.positionalVars.screenType == 'mobile') {
				return this.flexVar.custom_css_group.custom_css_mobile;
			} else {
				return this.flexVar.custom_css_group.custom_css_desktop;
			}
		},
	},
	mounted(){	
		//set flexContainer to be used within scope of this component
		this.parentFlexContainer = globalFunctions.closestParent(this.$refs.thisSection, '.flexContainer');
		
		//if flexContainer exists then add scrollHandler
		if (this.parentFlexContainer) {
			(this.parentFlexContainer).addEventListener('scroll', this.handleScroll);
		}
		
//		VanillaTilt.init(this.$refs.phoneContainer, {
//			max: 4,
//			speed: 2000,
//			scale: 1.02,
//			reverse: true,
//		});

	},
  methods: {
		handleScroll (event) {			
      // Any code to be executed when the window is scrolled
			// Handle all parallax actions
			if (this.$refs.phoneContainer) {
				this.addParallax();
			}
			
			// Handle flipping phone on scroll
			if ( this.$refs.phoneContainer ) {
				if ( 
					this.parentFlexContainer.scrollTop >= this.parallaxObjs[0].startScroll && 
					this.parentFlexContainer.scrollTop < this.parallaxObjs[0].stopScroll
				) {
					this.$refs.phoneContainer.classList.add("flipFront");
					this.$refs.phoneContainer.classList.remove("flipBack");
				} else if ( 
					( this.parentFlexContainer.scrollTop < this.parallaxObjs[0].startScroll || 
					this.parentFlexContainer.scrollTop >= this.parallaxObjs[0].stopScroll ) && 
					this.$refs.phoneContainer.classList.contains("flipFront")
				) {
					this.$refs.phoneContainer.classList.add("flipBack");
					this.$refs.phoneContainer.classList.remove("flipFront");
				}
			}
    },
		addParallax () {
			//phone content
			var phoneScroll = this.$refs.phoneScroll;
			var phoneContainer = this.$refs.phoneContainer;
			var imageContainer = this.$refs.imageContainer;
			var phoneHeader = this.$refs.phoneHeader;
			var stopPoint = $(phoneContainer).height() - $(phoneScroll).innerHeight() - $(phoneHeader).innerHeight() - parseInt($(imageContainer).css('padding-top'));
			
			this.parallaxObjs[0] = {
				startScroll: globalFunctions.parallaxStartStop(phoneContainer).start,
				stopScroll: globalFunctions.parallaxStartStop(phoneContainer).end,
				element: $(phoneScroll),
				cssProp: 'top',
				cssStart: 0,
				cssStop: stopPoint,
				cssUnit: 'px',
			};
//			console.log(this.parallaxObjs[0]);
			
			globalFunctions.parallaxHandler(this.$refs.thisSection, this.parallaxObjs);
    },
		defineMobileHeight () {
			//set the phoneFrame to the height of its internals
			var mobileH = this.$refs.phoneFrame.clientHeight;
			this.$refs.phoneContainer.style.height = mobileH - 1 + 'px'; // minus 1 rounding errors
    },
	},
	watch: {
	},
  created () {
  },
  destroyed () {
		//if flexContainer exists then add scrollHandler
		if (this.parentFlexContainer) {
			(this.parentFlexContainer).removeEventListener('scroll', this.handleScroll);
		}
  }
}
</script>

<style>	
</style>