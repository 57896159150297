<template>
  <div id="app"
			 v-bind:data-page="appVar.contentSection ? appVar.contentSection[0].post_name : ''"> 
		<headerHandler
			v-if="browserSupported"
			v-bind:extraVars="appVar.extraVars">
		</headerHandler>
		
<!--
		<div style="position: fixed; z-index:100; top: 20rem; left: 2rem; display: none;">
			<select v-model="transitionType">
				<option disabled value="">Please select a transition</option>
				<option v-for="transition in transitionArr" v-bind:value="transition">{{ transition }}</option>
			</select>
			<br><br>
			<button v-on:click="testApp">Test Active</button>
		</div>
-->
		
<!--
		<div v-html="testHtml" style="position: fixed; z-index:100; top: 30rem; left: 2rem; color: white; font-size: 14px;">
		</div>
-->
		
		<div v-if="!browserSupported" style="color: black; font-size: 14px;">
			You are using an unsupported browser, such as Internet Explorer. Please use a modern browser to view this site.
		</div>
		
		<div id="contentContainer" 
				 v-if="browserSupported"
				 v-bind:class="[{ active: appVar.extraVars.isTransitionClassActive }, transitionType ]">
			<div id="transitionContainer">
				<transition name="contentTransition">
					<flexHandler
						v-if="appVar.contentSection"
						v-bind:class="transitionType"
						v-bind:contentSection="appVar.contentSection"
						v-bind:extraVars="appVar.extraVars"
						:key="reRenderContent">
					</flexHandler>
				</transition>
				<div class="flexContainer dummy"></div>
				<div class="flexContainer dummy"></div>
				<div class="flexContainer dummy"></div>
				<div class="flexContainer dummy"></div>
				<div class="flexContainer dummy"></div>
			</div>
		</div>
		
		<div id="cursor">
			<span></span>
		</div>
		<div id="cursor-follower">
			<span></span>
		</div>
	</div>
</template>

<script>

import Vue from 'vue';
import headerHandler from './components/headerHandler.vue';
import navChevron from './components/navChevron.vue';
import flexHandler from './components/flexHandler.vue';
import * as globalFunctions from './js/globalFunctions.js';
import * as wpEndpoints from './js/wpEndpoints.js';

export default {
  name: 'app',
  data(){
    return {
      appVar: {
				contentSection: null,
				menuSection: null,
				postsIndex: null,
				extraVars: {
					isTransitionClassActive: false,
					flexiColor: {
						bg: '#000000',
						content: 'darkContent',
					},
					flexScrollPos: {
						pos: 0,
						lastpos: 0,
					},
					positionalVars: null,
					anchorPoints: [],
					primaryMenu: [],
				},
			},
			transitionType: '',
			transitionArr: [ 
				'none', 
				'fade', 
				'explodeUp', 
				'bounceInOut', 
				'bounceInOutDown', 
				'bounceLeftRight', 
				'zoomLeftRight', 
				'dice', 
			],
			reRenderContent: 0,
			pageName: '',
			browserSupported: true,
			testHtml:'test',
    }
  },
  components: {
    flexHandler,
    headerHandler,
    navChevron,
  },
  mounted(){
		
		var browser = this.get_browser();
    this.browserSupported = this.isSupported(browser);

		wpEndpoints.posts_index(this);
		wpEndpoints.menu_navs(this);
		globalFunctions.manageCursor(this);
		
		globalFunctions.resizeSensor(this);
		//set a safe transition to start with (dice looks odd first up)
		this.transitionType = 'zoomLeftRight';
		
		//fix window scroll to top, scrolling allowed only within flexContainer
		setInterval(function(){ 
			window.scrollTo(0, 0);
		}, 500);
		
  },
  methods: {
		testApp: function () {
      this.appVar.extraVars.isTransitionClassActive = !this.appVar.extraVars.isTransitionClassActive;
    },
		randomTransition: function() {
			var target;
			if (this.appVar.extraVars.positionalVars.screenType == "mobile") {
				//if mobile then exclude dice, which is last option
				target = Math.ceil(Math.random() * Math.floor(this.transitionArr.length - 2));
			} else {
				target = Math.ceil(Math.random() * Math.floor(this.transitionArr.length - 1));
			}
			
			this.transitionType = this.transitionArr[target];
//			this.transitionType = 'dice'; //forcing to test transition
		},
		setBGColor: function() {
			//fken useless v-bind on style doesn't work consistently. Use JS to set
			$("#app").css("background-color", this.appVar.extraVars.flexiColor.bg);
		},
		throttleScroll (newPos, oldPos, delay) {
			// If setTimeout is already scheduled, no need to do anything
			if (this.scrollThrottleTimer) {
				return;
			}

			// Schedule a setTimeout after delay seconds
			this.scrollThrottleTimer = setTimeout(() => {
				//add throttled code / function calls here
//				this.testHtml = $(body).scrollTop();
//				if (newPos > oldPos){
//					this.toggleFullScreen(true);
////					window.scrollTo(0, 100);
//				} else {
//					this.toggleFullScreen(false);
////					window.scrollTo(0, 0);
//				}
				
				this.scrollThrottleTimer = undefined;
			}, delay);
		},
		toggleFullScreen(fullScreen) {
			var doc = window.document;
			var docEl = doc.documentElement;

			var requestFullScreen = docEl.requestFullscreen || docEl.mozRequestFullScreen || docEl.webkitRequestFullScreen || docEl.msRequestFullscreen;
			var cancelFullScreen = doc.exitFullscreen || doc.mozCancelFullScreen || doc.webkitExitFullscreen || doc.msExitFullscreen;

			if(fullScreen) {
				requestFullScreen.call(docEl);
			}
			else {
				cancelFullScreen.call(doc);
			}
		},
		get_browser: function () {
       var ua = navigator.userAgent, tem, M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
       if (/trident/i.test(M[1])) {
         tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
         return { name: 'IE', version: (tem[1] || '') };
       }
       if (M[1] === 'Chrome') {
         tem = ua.match(/\bOPR\/(\d+)/)
         if (tem != null) { return { name: 'Opera', version: tem[1] }; }
       }
       if (window.navigator.userAgent.indexOf("Edge") > -1) {
         tem = ua.match(/\Edge\/(\d+)/)
         if (tem != null) { return { name: 'Edge', version: tem[1] }; }      
       }
       M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
       if ((tem = ua.match(/version\/(\d+)/i)) != null) { M.splice(1, 1, tem[1]); }
       return {
         name: M[0],
         version: +M[1]
       };
     },
		isSupported (browser) {
			var supported = true;
				if ((browser.name === "MSIE" || browser.name === "IE") && browser.version <= 11) {
					supported = false;
				}
			return supported;
		},
  },
	computed: {
		routerPath: function() {
			return this.$route.path;
		}
	},
	watch: {
		routerPath: function(newPath, oldPath) {
//			console.log(oldPath + '->' + newPath);
			wpEndpoints.route_post_acf(this);
		},
		'appVar.extraVars.isTransitionClassActive':  {
			handler(val) {
				if (!val) { 
					// apply random page transition when transition complete
					this.randomTransition();
				}
			},
		},
		'appVar.menuSection': {
			handler(val) {
				//populate menu (primary only) when API call returned
				let menuChecker;
				if (val.length >= 1 && Array.isArray(val)) {
					menuChecker = val.filter(function(menu){
						if(menu.menuName == "primary") return menu.menuContent;
					});
					if(menuChecker.length > 0) {
						//check if any parent, if so place inside parent, only 2 levels
						let tmpMenu1 = menuChecker[0].menuContent;
						let tmpMenu2 = [];
						tmpMenu1.forEach(tmpEl => {
							if (tmpEl.menu_item_parent == 0) {
								tmpMenu2.push(tmpEl);
							} else {
								tmpMenu2.forEach((el, ind) => {
									if (el.ID == tmpEl.menu_item_parent) {
										if(!tmpMenu2[ind]["children"]){
											tmpMenu2[ind]["children"] = [];
										}
										tmpMenu2[ind]["children"].push(tmpEl);
									}
								});
							}
						});
						this.appVar.extraVars.primaryMenu = tmpMenu2;
					}
				}
			},
			deep: true
		},
		'appVar.extraVars.flexScrollPos.pos': function(newPos, oldPos) {
			//add unthrottled scroll code here
			
			//run throttled scroll code
			var delay = 500;
			this.throttleScroll(newPos, oldPos, delay);
		},
		
	},
  created() {
		//determine when transition is running on flex component and add to parent elements
    this.$root.$on('isTransitionActive', (status) => {
    	this.appVar.extraVars.isTransitionClassActive = status;
    });
		//determine scroll position from main flexHandler element
    this.$root.$on('flexScrollPos', (position) => {
   		this.appVar.extraVars.flexScrollPos.pos = position;
    });
		//when flexhandler updates anchorpoints, push on to header handler to populate
    this.$root.$on('anchorPoints', (points) => {
    	this.appVar.extraVars.anchorPoints = points;
    });
		//manage color updates when prompted by flex sections
    this.$root.$on('flexiColorEvent', (data) => {
			this.appVar.extraVars.flexiColor = data;
			this.setBGColor();
    });
		//when a component wants to force a re-render
    this.$root.$on('reRenderReq', (data) => {
			this.reRenderContent++;
    });
  },
	
}
</script>

<style lang="sass">
	@import "./scss/style.scss"
</style>
