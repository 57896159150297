<template>
	<div class="flexContainer"
			 ref="flexContainer">
		<navChevron 
			v-bind:extraVars="extraVars">
		</navChevron>
				
<!-- Normal pages -->
		<div v-if="contentSection[0].acf && contentSection[0].post_type == 'page'"
				 class="pageContent">
			<template v-for="(flex, index) in contentSection[0].acf.flexible_content_area">
					<component v-bind:flexVar="flex" 
										 v-bind:extraVars="extraVars" 
										 v-bind:flexCount="index + 1" 
										 :is="flex.acf_fc_layout">
					</component>
			</template>
		</div>

<!-- Project (post) pages -->
		<div v-if="contentSection[0].acf && contentSection[0].post_type == 'projects'"
				 class="pageContent">	
			<div class="widthLimit projectsPost" ref="projectsPost"
					 :class="extraVars.flexiColor.content">
				<div class="infoPanel animateSection flexiColor" ref="infoPanel">
					<div class="topFaderI" ref="topFaderI"></div>
					<h4 class="name" ref="postName" v-html="contentSection[0].acf.project_name"></h4>
					<div class="excerpt" ref="excerpt" v-html="contentSection[0].post_excerpt"></div>
					<div class="imageContainer">
						<img class="featuredImg" :src="contentSection[0].featured_image">
					</div>
					<div class="logoContainer">
						<img class="logo" 
								 v-for="logo in contentSection[0].acf.logo_repeater"
								 v-bind:src="[extraVars.flexiColor.content=='darkContent' ? logo.dark_logo.url : logo.light_logo.url]"> 
					</div>
					<div class="cta" ref="cta" v-if="contentSection[0].acf.cta.title">
						<a class="ademicButton" ref="ctaBtn" :class="extraVars.flexiColor.content"
							 :href="contentSection[0].acf.cta.url" target="_blank">
							<div class="mainBG"></div>
							<div class="hoverBG"></div>
							<div class="btnOuterLeftChev btnOuterChev"></div>
							<div class="btnOuterRightChev btnOuterChev"></div>
							<div class="btnTxt">{{ contentSection[0].acf.cta.title }}</div>
						</a>
					</div>
				</div>
				
				<div class="contentPanel">
					<div class="topFaderC" ref="topFaderC"></div>
					<template v-for="(flex, index) in contentSection[0].acf.flexible_content_area">
							<component v-bind:flexVar="flex" 
												 v-bind:extraVars="extraVars" 
												 v-bind:flexCount="index + 1" 
												 :is="flex.acf_fc_layout">
							</component>
					</template>
					<div class="navPrevNext">
						<div v-if="contentSection[0].prev_post" 
								 class="prevLink animateSection">
							<div class="bgImg"
									 v-bind:style="{ 'background-image': 'url(' + contentSection[0].prev_post.featured_image + ')' }"></div>
							<router-link 
								:data-postID="contentSection[0].prev_post.id" 
								:to="contentSection[0].prev_post.url">
								<h6>PREVIOUS PROJECT</h6>
								<div v-html="contentSection[0].prev_post.acf.project_name"
										 class="regular"></div>
							</router-link>
						</div>
						<!-- If no previous project, then create dummy for desktop spacing -->
						<div v-if="contentSection[0].prev_post==null" 
								 class="prevLink animateSection dummyTabletOnly">
						</div>
						<div v-if="contentSection[0].next_post" 
								 class="nextLink animateSection">
							<div class="bgImg"
									 v-bind:style="{ 'background-image': 'url(' + contentSection[0].next_post.featured_image + ')' }"></div>
							<router-link
								:data-postID="contentSection[0].next_post.id" 
								:to="contentSection[0].next_post.url">
								<h6>NEXT PROJECT</h6>
								<div v-html="contentSection[0].next_post.acf.project_name"
										 class="regular"></div>
							</router-link>

						</div>
					</div>
				</div>
			</div>
			

		</div>

<!--
		<div style="position: fixed; z-index:100; top: 20rem; left: 2rem; background: white; padding:1rem;">
			<div id="testPanel">Test</div>
		</div>
-->

		<footerHandler 
			v-bind:extraVars="extraVars">
		</footerHandler>
	</div>
</template>

<script>
import Vue from 'vue';
import * as globalFunctions from '@/js/globalFunctions.js';
import footerHandler from '@/components/footerHandler.vue';
import navChevron from '@/components/navChevron.vue';
import flex_01 from './flex/flex_01.vue';
import flex_02 from './flex/flex_02.vue';
import flex_03 from './flex/flex_03.vue';
import flex_04 from './flex/flex_04.vue';
import flex_05 from './flex/flex_05.vue';
import flex_06 from './flex/flex_06.vue';
import flex_07 from './flex/flex_07.vue';
import flex_08 from './flex/flex_08.vue';
import flex_09 from './flex/flex_09.vue';
import flex_10 from './flex/flex_10.vue';
import flex_11 from './flex/flex_11.vue';
import flex_12 from './flex/flex_12.vue';
import flex_13 from './flex/flex_13.vue';
	
export default {
  name: 'flexHandler',
  components: {
		footerHandler,
		navChevron,
		flex_01,
		flex_02,
		flex_03,
		flex_04,
		flex_05,
		flex_06,
		flex_07,
		flex_08,
		flex_09,
		flex_10,
		flex_11,
		flex_12,
		flex_13,
  },
  data(){
    return {
			anchorPoints: [],
			scrollThrottleTimer: undefined,
		}
	},
  props: {
		contentSection: Array,
		extraVars: Object,
  },
 	watch: { 
		'extraVars.isTransitionClassActive':  {
			handler(val) {
				if (!val){
					// after transition, check for anchor url parameters and go to anchor if present
					globalFunctions.scrollAnchor(this.$refs.flexContainer);
					
					//workaround glitch in vue topFaderI was covering content momentarily
					this.$nextTick(() => {
						this.infoPanelMob();
					});
				}
			},
			deep: true
		},
		'extraVars.flexScrollPos.pos': function(newPos, oldPos) {
			//add unthrottled scroll code here
//			var testHtml = "inner: " + window.innerHeight + 
//					"<br> outer: " + window.outerHeight +
//					"<br> flexH: " + flexH +
//					"<br> footerP: " + footerPos +
//					"<br> scroll: " + this.extraVars.flexScrollPos.pos;
//			$("#testPanel").html(testHtml);
			
			
			//if mobile then manage CTA
			if(this.extraVars.positionalVars.screenType == "mobile" && this.$refs.cta) {
				this.manageCTA();
			} 
			//run throttled scroll code
			var delay = 500;
			this.throttleScroll(newPos, oldPos, delay);
		},
		'extraVars.positionalVars.screenWidth': function(val) {
			//if screenWidth changes recalc infoPanel width
			this.infoPanelDesk();
			this.infoPanelMob();
		},
		'extraVars.positionalVars.screenType': function(val) {
			//if change between desktop and mobile, reload
			this.$root.$emit('reRenderReq', true);
		},
	},
	mounted() {
		//observe flexContainer for changes in class list
		this.observer = new MutationObserver(mutations => {
			for (const m of mutations) {
				const newValue = m.target.getAttribute(m.attributeName);
				this.$nextTick(() => {
					this.onClassChange(newValue, m.oldValue);
				});
			}
		});
		this.observer.observe(this.$refs.flexContainer, {
			attributes: true,
			attributeOldValue : true,
			attributeFilter: ['class'],
		});
		
		//manage action on flexcontainer scroll, incl distribution of position
		this.$refs.flexContainer.addEventListener('scroll', this.manageScroll);
		
		//manage anchor points once instantiated
		this.populateAnchor();
		
		//if projects page, 
		if(this.$refs.projectsPost) {
			//set width of infoPanel
			this.infoPanelDesk();
			//set colors
			this.projectColors();
		} else {
			//set background color to white after each page load
			this.$root.$emit('flexiColorEvent', {
				bg: '#FFF',
				content: 'darkContent',
			});
		}
		
//		console.log(this.contentSection);
//		console.log(this.extraVars);
	},
  methods: {
		onClassChange(classAttrValue) {
			//when class list on flexcontainer detected, search for contentTransition class
			const classList = classAttrValue.split(' ');
			const searchTerm = 'contentTransition';
			var transitionActive = false;
			
			classList.forEach(function(str, idx) {
				if (str.indexOf(searchTerm) !== -1 ) {
//					console.log(classList[idx] + ' contains "' + searchTerm  + '" at index ' + idx + ' of arr');
					transitionActive = true;
				}
			});
			
			this.$root.$emit('isTransitionActive', transitionActive);

		},
		manageScroll() {
			//return scroll position of flexcontainer to root, so header can use it to animate
			if(this.$refs.flexContainer){
				this.$root.$emit('flexScrollPos', this.$refs.flexContainer.scrollTop);
			}	
		},
		populateAnchor() {
			//scan for all anchors in flexcontainer and populate array
			var flexContainer = this.$refs.flexContainer;
			var pointsEl = $(flexContainer).find('.anchorContainer .titleAnchor');
			var points = [];
			
			$.each(pointsEl, function(){
				//get element position relative to flexContainer current scroll pos
//				var pos = this.getBoundingClientRect().top + flexContainer.scrollTop; //JS 
				var pos = $(this).offset().top + $(flexContainer).scrollTop(); //JQuery 
				var doLine = $(this).hasClass("doTitleLine");

				var pt = {
					anchorName: $(this).attr('data-anchor'),
					anchorTitle: $(this).text(),
					anchorSubtitle: $(this).attr('data-subtitle'),
					anchorPos: pos,
					anchorLine: doLine,
				}
				points.push(pt);
			});
			
			//send this up to root so header can be populated as well
			this.$root.$emit('anchorPoints', points);
		},
		infoPanelDesk() {
			if(this.extraVars.positionalVars.screenType == "desktop") {
				//resets
				$(this.$refs.excerpt).css('margin-top', 'initial');
				$(this.$refs.topFaderI).height('initial');
				$(this.$refs.ctaBtn).css('right', 'initial');
				$(this.$refs.cta).height('initial');
				
				//if desktop, set width of infoPanel 33% - 2rem
				let targW = ($(this.$refs.projectsPost).width() * 0.33) - globalFunctions.remToPixels(2);
				$(this.$refs.infoPanel).width(targW);
				
				//detect scroll on fixed item and push to flexContainer
				globalFunctions.bubbleScroll(this.$refs.infoPanel);
				globalFunctions.bubbleScroll(this.$refs.topFaderC);
				
			} else {
				//resets
				$(this.$refs.infoPanel).width("initial");
				
				//if mobile, set height of topFaderI and margin-top of excerpt, based on height of name
				let nameH = $(this.$refs.postName).height();
				$(this.$refs.excerpt).css('margin-top', nameH + globalFunctions.remToPixels(2));
				$(this.$refs.topFaderI).height(nameH + globalFunctions.remToPixels(8));
				
				//set central position for CTA
				let btnW = $(this.$refs.ctaBtn).outerWidth(true);
				let ctaW = $(this.$refs.cta).width();
				$(this.$refs.ctaBtn).attr('data-rightc', 0.5 * (ctaW - btnW));
				$(this.$refs.ctaBtn).css('right', parseInt($(this.$refs.ctaBtn).attr('data-rightc')));
				
				//permanently set CTA height so it retains space in DOM flow
				$(this.$refs.cta).height($(this.$refs.cta).height());
			}	
		},
		infoPanelMob() {
			if(this.extraVars.positionalVars.screenType == "mobile") {
				//set CSS for topFader
				let bgColor = this.contentSection[0].acf.background_color;
				let bgCSS = {background: "linear-gradient(to bottom, " + bgColor + " 90%, " + bgColor + "00 100%)"};
				$(this.$refs.topFaderI).css(bgCSS);
				
				//detect scroll on fixed item and push to flexContainer
				globalFunctions.bubbleScroll(this.$refs.topFaderI);
				globalFunctions.bubbleScroll(this.$refs.postName);
				globalFunctions.bubbleScroll(this.$refs.cta);
			}
		},
		projectColors() {
			//set background and content colors
			let bgColor = this.contentSection[0].acf.background_color;
			this.$root.$emit('flexiColorEvent', {
				bg: bgColor,
				content: this.contentSection[0].acf.content_color,
			});
			
			let bgCSS = {background: "linear-gradient(to bottom, " + bgColor + " 90%, " + bgColor + "00 100%)"};
			$(this.$refs.topFaderC).css(bgCSS);
		},
		throttleScroll (newPos, oldPos, delay) {
			// If setTimeout is already scheduled, no need to do anything
			if (this.scrollThrottleTimer) {
				return;
			}

			// Schedule a setTimeout after delay seconds
			this.scrollThrottleTimer = setTimeout(() => {
				//add throttled code / function calls here
				
				//update anchor points to account for change of heights during scroll anim
				this.populateAnchor();
				
				this.scrollThrottleTimer = undefined;
			}, delay);
		},
		manageCTA() {
			let faderH = $(this.$refs.topFaderI).outerHeight(true);
			let namePos = $(this.$refs.postName).offset().top;
			let flexH = $(this.$refs.flexContainer).prop("scrollHeight");
			let footerH = $(".siteFooter").first().outerHeight(true);
			let footerPos = $(".siteFooter").first().offset().top;
			
			if ($(this.$refs.cta).offset().top > faderH ) {
				//natural button position, not yet hit top
				$(this.$refs.cta).removeClass("minTop");
				$(this.$refs.ctaBtn)
					.css('right', parseInt($(this.$refs.ctaBtn).attr('data-rightc')))
					.css('position', 'relative')
					.css('margin-right', 'initial')
					.css('top', 'initial');
			} else {
				if ($(this.$refs.cta).offset().top > namePos ) {
					//hit transparent header, but need to natural scroll before fixed
					$(this.$refs.cta).addClass("minTop");
					$(this.$refs.ctaBtn)
						.css('top', 'initial')
						.css('right', 5)
						.css('margin-right', 'initial')
						.css('position', 'relative');
				} else {
					//hit fixed area and footer not yet end of page
					$(this.$refs.cta).addClass("minTop");
					$(this.$refs.ctaBtn)
						.css('top', namePos)
						.css('right', 5)
						.css('margin-right', '1.5rem')
						.css('position', 'fixed');
				}
			}
		},
  },
	beforeDestroy() {
		//if dice transition, scroll to top
		if ( this.$refs.flexContainer.classList.contains('dice') ) {
			globalFunctions.cstmScrollTo(this.$refs.flexContainer.scrollTop, 0, 500, false, this.$refs.flexContainer);
		}
		this.observer.disconnect();
	}, 
}
</script>

<style>
</style>
