export var globalVars = {
	genericAnimationTime: 300,
}

// manage cursor
export function manageCursor(appThis) {
	var cursorAnimateTime = 1000;
	var cursor = $('#cursor');
	var cursorSpan = $('#cursor span');
	var cursorFollower = $('#cursor-follower');
	var cursorTimer;
	
	$(document).mousemove(function(e){
		var x = e.clientX;
		var y = e.clientY;
		cursor
			.css('transform', 'matrix(1, 0, 0, 1, ' + x + ', ' + y + ')')
			.addClass('show');
		cursorFollower
			.css('transform', 'matrix(1, 0, 0, 1, ' + x + ', ' + y + ')')
			.addClass('show');
		
		//if no mouse movement, set follower to retract;
		clearInterval(cursorTimer);
		cursorTimer = setTimeout(function(){
			cursorFollower.removeClass('show');
		}, cursorAnimateTime);
	});
	$(document).mouseleave(function(e){
		cursor.removeClass('show');
		cursorFollower.removeClass('show');
	});
	
	$(document).on('mouseenter', 'a:not(.cursorNoExpand), a .linkText', function(e){
		cursorSpan.addClass('expand');
	});
	$(document).on('mouseleave', 'a:not(.cursorNoExpand), a .linkText', function(e){
		cursorSpan.removeClass('expand');
	});
	$(document).on('mouseenter', 'a', function(e){
		cursorFollower.addClass('linkHide');
	});
	$(document).on('mouseleave', 'a', function(e){
		cursorFollower.removeClass('linkHide');
	});

}

// sense resize and update app variable to propagate to all flexes
// also check for scrollbars and adjust fixed items accoringly
export function resizeSensor(appThis) {
	refreshPosVars(appThis);
	window.addEventListener("resize", function(e){
		refreshPosVars(appThis);
	});
}
export function refreshPosVars(appThis) {
	//determine if scrollbar exists, then add width to fixed items
	var scrollWidth = 0;
	var scrollHeight = 0;
	var flexContainer = jQuery('.flexContainer').first();
	if (flexContainer) {
		scrollWidth = flexContainer.outerWidth(true) - flexContainer.width();
		scrollHeight = flexContainer.outerHeight(true) - flexContainer.height();
	}
	appThis.appVar.extraVars.positionalVars = {
		screenWidth: window.innerWidth,
		screenType: displayType(window.innerWidth),
		scrollWidth: scrollWidth,
		scrollHeight: scrollHeight,
	};
}
export function displayType(width) {
	var returnDisplay;
	if (width >= 1200) {
		returnDisplay = "desktop";
	} else {
		returnDisplay = "mobile";
	}
	return returnDisplay;
}

//ensure user is returned to scroll position after animations done
export function refocusElement(el, offset, scrollContainer){
	var elPos;
	if(scrollContainer === undefined) {
	 	scrollContainer = 'window';
	}
	if (isNaN(offset) || offset == ""){ //is not a number, no offset, use normal calcs
		if (scrollContainer == 'window') {
			elPos = el.getBoundingClientRect().top - window.pageYOffset;
		} else {
			elPos = el.getBoundingClientRect().top - scrollContainer.scrollTop;
		}
	} else {
		elPos = offset;
	}
	
	setTimeout(function(){
		if (scrollContainer == 'window') {
			cstmScrollTo(window.pageYOffset, el.getBoundingClientRect().top - elPos, 500);
		} else {
			cstmScrollTo(scrollContainer.scrollTop, el.getBoundingClientRect().top - elPos, 500, false, scrollContainer);
		}
	}, globalVars.genericAnimationTime);
}

//animated scrolling for x-browser functionality
//Scrollto copied from github.com/andjosh https://gist.github.com/andjosh/6764939
export function cstmScrollTo(start, to, duration, onlyDown, targetElement) {
	//ES5 default parameters
	if(start === undefined) {
	 	start = 0;
	}
	if(to === undefined) {
	 	to = 0;
	}
	if(duration === undefined) {
	 	duration = 1000;
	}
	if(onlyDown === undefined) {
	 	onlyDown = false;
	}
	if(targetElement === undefined) {
	 	targetElement = window;
	}
	
	if(onlyDown && (start > to)){
		return;
	}
	
	const change = to - start;
	const increment = 200;
	let currentTime = 0;
	const startDate = +new Date();

  var animateScroll = function() {
		const currentDate = +new Date();
		const currentTime = currentDate - startDate;
		targetElement.scrollTo(0, parseInt(easeInOutQuad(currentTime, start, change, duration)));
		if(currentTime < duration) {
				requestAnimationFrame(animateScroll);
		}
		else {
				targetElement.scrollTo(0, to);
		}
	};	
	
//	var animateScroll = function animateScroll() {
//		currentTime += increment;
//		var val = easeInOutQuad(currentTime, start, change, duration);
//		targetElement.scrollTo(0, val);
//
//		if (currentTime < duration) {
//			setTimeout(animateScroll, increment);
//		}
//	};

	animateScroll();
};
function easeInOutQuad (t, b, c, d) {
	t /= d / 2;
	if (t < 1) return c / 2 * t * t + b;
	t--;
	return -c / 2 * (t * (t - 2) - 1) + b;
};

//manage appearance of triangle
export function manageTriangle(el){
	var flexContainer = closestParent(el, '.flexContainer');
	if ( flexContainer.scrollTop >= parallaxStartStop(el).start ) {
		el.classList.add("active");
	}
}

//manage parallax
export function parallaxHandler(thisSection, parallaxObjs){
	var flexContainer = closestParent(thisSection, '.flexContainer');
	$.each(parallaxObjs, function(){
		parallax(flexContainer.scrollTop, this);
//		console.log(flexContainer.scrollTop);
	});
}
export function parallax(scrollPos, options) {
	//default parameters
	scrollPos = (typeof scrollPos === 'undefined') ? 0 : scrollPos;
	options.startScroll = (typeof options.startScroll === 'undefined') ? 0 : options.startScroll;
	options.stopScroll = (typeof options.stopScroll === 'undefined') ? 0 : options.stopScroll;
	options.element = (typeof options.element === 'undefined') ? jQuery('body') : options.element;
	options.cssProp = (typeof options.cssProp === 'undefined') ? 'top' : options.cssProp;
	options.multi = (typeof options.multi === 'undefined') ? false : options.multi;
	options.prefix = (typeof options.prefix === 'undefined') ? '' : options.prefix;
	options.suffix = (typeof options.suffix === 'undefined') ? '' : options.suffix;
	
	var cssFinalVal = "";
	
	if (options.multi) {
		$(options.subprops).each(function(i, val){
			var returnedVal = parallaxCalc({
				stopScroll: options.stopScroll, 
				startScroll: options.startScroll, 
				cssStop: val.cssStop, 
				cssStart: val.cssStart, 
				cssUnit: val.cssUnit, 
				updateStart: val.updateStart, 
				updateStop: val.updateStop, 
				scrollPos: scrollPos,
			})
			
			//exit function if no valid values defined
			if (!returnedVal) { return; }
			
			cssFinalVal += val.subprop + "(" + returnedVal + ") ";
		});
		
	} else {
		cssFinalVal = parallaxCalc({
			stopScroll: options.stopScroll, 
			startScroll: options.startScroll, 
			cssStop: options.cssStop, 
			cssStart: options.cssStart, 
			cssUnit: options.cssUnit, 
			scrollPos: scrollPos,
		});

		cssFinalVal = options.prefix + cssFinalVal + options.suffix;
	}

	//exit function if no valid values defined
	if (!cssFinalVal) { return; }
	
	options.element.css(options.cssProp, cssFinalVal);
}
function parallaxCalc(calcParams) {
	calcParams.cssStart = (typeof calcParams.cssStart === 'undefined') ? 0 : calcParams.cssStart;
	calcParams.cssStop = (typeof calcParams.cssStop === 'undefined') ? 0 : calcParams.cssStop;
	calcParams.cssUnit = (typeof calcParams.cssUnit === 'undefined') ? 'px' : calcParams.cssUnit;
	calcParams.updateStart = (typeof calcParams.updateStart === 'undefined') ? true : calcParams.updateStart;
	calcParams.updateStop = (typeof calcParams.updateStop === 'undefined') ? true : calcParams.updateStop;
	
	//calculate movement relative to scroll position
	var scrollRange = calcParams.stopScroll - calcParams.startScroll;
	var cssPropRange = calcParams.cssStop - calcParams.cssStart;
	var cssVal = ((calcParams.scrollPos - calcParams.startScroll) * cssPropRange / scrollRange) + calcParams.cssStart;
	var returnCSSVal;
	
	//assign css property if within range
	if ( (calcParams.scrollPos > calcParams.startScroll) && (calcParams.scrollPos < calcParams.stopScroll) ){
		returnCSSVal = cssVal + calcParams.cssUnit;
	}
	
	//asign max / min values if default behaviour applies
	if ( calcParams.updateStart && (calcParams.scrollPos <= calcParams.startScroll) ){
		returnCSSVal = calcParams.cssStart + calcParams.cssUnit;
	} else if ( calcParams.updateStop && (calcParams.scrollPos >= calcParams.stopScroll) ){
		returnCSSVal = calcParams.cssStop + calcParams.cssUnit;
	}
	
	return returnCSSVal;
}
export function parallaxStartStop(el, screenOffset){
	if(screenOffset === undefined) {
	 	screenOffset = 0.5;
	}
	//estimate vertical scroll start position based on window height
	var flexContainer = closestParent(el, '.flexContainer');
	var windowHeight = flexContainer.offsetHeight;
	var windowScrollPos = flexContainer.scrollTop;
	var elTop =  el.getBoundingClientRect().top;
	var elHeight = el.offsetHeight;
	var trigger = elTop - ((1 - screenOffset) * windowHeight);
	var start = (trigger + windowScrollPos) > 1 ? (trigger + windowScrollPos) : 0;
	var end = windowScrollPos + elTop + elHeight - (screenOffset * windowHeight);

//	console.log('trigger: ' + trigger);
//	console.log('elTop: ' + elTop);
//	console.log('windowScrollPos: ' + windowScrollPos);
	
	return {
		start: start,
		end: end
	}
}

//polyfill for jquery closest
export function closestParent(el, selector) {
    var matchesFn;

    // find vendor prefix
    ['matches','webkitMatchesSelector','mozMatchesSelector','msMatchesSelector','oMatchesSelector'].some(function(fn) {
        if (typeof document.body[fn] == 'function') {
            matchesFn = fn;
            return true;
        }
        return false;
    })

    var parent;

    // traverse parents
    while (el) {
        parent = el.parentElement;
        if (parent && parent[matchesFn](selector)) {
            return parent;
        }
        el = parent;
    }

    return null;
}

//return dimensions as attributes on the parent
export function BGImageSize(el){
	var urlSource = $(el).css('background-image').replace(/url\((['"])?(.*?)\1\)/gi, '$2').split(',')[0];
	var image = new Image();
	image.src = urlSource;
	
	image.onload = function() {
		$(el).attr('data-bgHeight', image.height);
		$(el).attr('data-bgWidth', image.width);
	}
}

//determine if current section is in scroll view
export function sectionActive(el){
	var active = false;
	var flexContainer = closestParent(el, '.flexContainer');
	if ( (flexContainer.scrollTop >= parallaxStartStop(el).start ) &&
				flexContainer.scrollTop <= parallaxStartStop(el).end ) {
		
		active = true; //flag to send colors back to root
	} 
	
	return active;
}

//manage scroll to anchor point
export function scrollAnchor(flexContainer){
	//check url for anchor
	var urlParams = new URLSearchParams(window.location.search);
	var anchorName = urlParams.get('anchor');
	
	if(anchorName){
		//check that anchor attribute exists on page
		var targetEl = document.querySelector(".anchorContainer *[data-anchor='" + anchorName + "']");
		
		if(targetEl){
		//calculate anchor distance, mobile header or desktop chevron
			var topSpace = 0;
			if (displayType(window.innerWidth) == "mobile") {
				topSpace =  document.querySelector('header .headerLeftContainer').offsetHeight + 35;
			} else {
				topSpace = document.querySelector(".bigChevContainer .leftNavChev").offsetHeight / 2;
			}
			
			//get element position relative to flexContainer current scroll pos
			var elPos = targetEl.getBoundingClientRect().top + flexContainer.scrollTop;
			
			var targetPos = elPos - topSpace + (targetEl.offsetHeight / 2);
			//smooth scroll to anchor point
			cstmScrollTo(flexContainer.scrollTop, targetPos, 1000, false, flexContainer);
		}
	}
}

//bubble scroll on fixed object to flexContainer
export function bubbleScroll(el) {
	let myFlexParent = $(el).closest('.flexContainer');
	$(el).bind('mousewheel', function(e){
		myFlexParent.scrollTop(myFlexParent.scrollTop() + e.originalEvent.deltaY);
	});
}

// convert rem to px
export function remToPixels(rem) {    
	return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
}