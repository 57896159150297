<template>
	<section class="flex_01 animateSection" 
					 :data-fcpCount="flexCount"
					 :style="customCSS"
					 ref="thisSection">
		
		<div class="widthLimit sectionContainer" 
				 v-bind:class="{ indentRight : flexVar.indent_right }">
			<div class="boxContainer" 
					 v-bind:class="{ boxMode : flexVar.box_mode }"
					 v-bind:style="[ flexVar.box_mode ? boxCSS : '' ]">
				<div class="wysiwyg" v-html="flexVar.wysiwyg_content"></div>
				<div class="expandoBox" 
						 v-if="flexVar.expando"
						 v-bind:class="{ expanded : isExpanded }">
					<div class="expandoContainer"
							 v-bind:style="expandoContainerStyle">
						<div class="expandoContent wysiwyg" 
								 v-html="flexVar.expando_content"
								 ref="expandoContent"></div>
					</div>
					<div class="hoverItem"></div>
					<div class="showMore"
							 v-on:click="showClicked"
							 ref="showMore">
						{{ expandoTxt }}
					</div>
					<div class="close"
							 v-on:click="showClicked">
					</div>
				</div>			
			</div>
	</div>
		
<!--
	<pre>{{ flexVar }}</pre>
-->
		
	</section>
</template>

<script>

import * as globalFunctions from '@/js/globalFunctions.js';
	
export default {
  name: 'flex_01',
  props: {
		flexVar: Object,
		flexCount: Number,
		extraVars: Object,
  },
  data(){
    return {
			boxCSS: {
				color: this.flexVar.box_text_color,
				'background-color': this.flexVar.box_background_color,
			},
			isExpanded: false,
			expandoTxt: 'SHOW MORE',
			expandoContainerStyle: {
				height: 0,
			},
			parentFlexContainer: '',
		}
	},
	computed: {
		customCSS: function() {
			if ( this.extraVars.positionalVars.screenType == 'mobile') {
				return this.flexVar.custom_css_group.custom_css_mobile;
			} else {
				return this.flexVar.custom_css_group.custom_css_desktop;
			}
		},
	},
	mounted(){
		//ensure box mode text color pushed down to all
		var thisSection = this.$refs.thisSection;
		$(thisSection).find(".wysiwyg").children().css('color', this.flexVar.box_text_color);
		
		//set flexContainer to be used within scope of this component
		this.parentFlexContainer = globalFunctions.closestParent(this.$refs.thisSection, '.flexContainer');
		
	},
  methods: {
		showClicked: function () {
			if ( !this.isExpanded ) {
				//calculate and apply height to expandoContent
				var targetHeight = this.$refs.expandoContent.clientHeight;
				this.expandoContainerStyle = { height: targetHeight + 'px' };
				this.expandoTxt = 'SHOW LESS';
			} else {
				this.expandoContainerStyle = { height: 0 };
				this.expandoTxt = 'SHOW MORE';
				globalFunctions.refocusElement(this.$refs.showMore, "", this.parentFlexContainer);
			}
			this.isExpanded = !this.isExpanded;
		},
	},
  created () {
  },
  destroyed () {
  }
}
</script>

<style>	
</style>