<template>
	<section class="flex_12 animateSection" 
					 :class="[flexVar.dark_background ? 'darkBG' : 'lightBG' ]"
					 :data-fcpCount="flexCount"
					 ref="thisSection">
		
		<div class="bgChev">
			<div class="leftChev"></div>
			<div class="rightChev"></div>
		</div>
		
		<div class="widthLimit">
			<div class="contentContainer">
				<h5 class="content"
						 v-html="flexVar.content">
				</h5>
				<div class="cta">
					<router-link 
						class="ademicButton"
						:class="[flexVar.dark_background ? 'lightContent' : '' ]"
						:to="flexVar.cta.url">
						<div class="mainBG"></div>
						<div class="hoverBG"></div>
						<div class="btnOuterLeftChev btnOuterChev"></div>
						<div class="btnOuterRightChev btnOuterChev"></div>
						<div class="btnTxt">{{ flexVar.cta.title }}</div>
					</router-link>
				</div>
			</div>
		</div>
	</section>
</template>

<script>

import * as globalFunctions from '@/js/globalFunctions.js';
import * as wpEndpoints from '@/js/wpEndpoints.js';
	
export default {
  name: 'flex_12',
  props: {
		flexVar: Object,
		extraVars: Object,
		flexCount: Number,
  },
  data(){
    return {
			parentFlexContainer: '',
			parallaxObjs: [],
			scrollThrottleTimer: undefined,
		}
	},
	computed: {
	},
	mounted(){	
		//set flexContainer to be used within scope of this component
		this.parentFlexContainer = globalFunctions.closestParent(this.$refs.thisSection, '.flexContainer');
		
	},
  methods: {
		throttleScroll (newPos, oldPos, delay) {
			// If setTimeout is already scheduled, no need to do anything
			if (this.scrollThrottleTimer) {
				return;
			}

			// Schedule a setTimeout after delay seconds
			this.scrollThrottleTimer = setTimeout(() => {
				//add throttled code / function calls here
				
				this.scrollThrottleTimer = undefined;
			}, delay);
		},
		
	},
	watch: {
		'extraVars.flexScrollPos.pos': function(newPos, oldPos) {
			//add unthrottled scroll code here
			
			//run throttled scroll code
			var delay = 500;
			this.throttleScroll(newPos, oldPos, delay);
		},
	},
  created () {
  },
  destroyed () {
  },
}
</script>

<style>	
</style>