<template>
	<header class="flexiColor"
					:class="[desktopExpandClass, extraVars.flexiColor.content]"
					ref="topHeader">
		<div class="headerLeftContainer"
				 ref="headerLeftContainer"
				 :class="menuActiveClass">
			<div id="logoContainer" class="logoContainer">
				<router-link 
					to="/"
					v-on:click.native="logoClick">
					<div class="svgContainer">
						<svg id="logo_leftChev" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.647 25.87">
							<path d="M7622.2,249.477l-8.471,12.477a.534.534,0,0,0,0,.6l8.614,12.481a.4.4,0,0,0,.327.171H7626a.281.281,0,0,0,.232-.44l-8.432-12.343a.4.4,0,0,1,0-.449l8.428-12.235a.281.281,0,0,0-.231-.441h-3.472A.4.4,0,0,0,7622.2,249.477Z" transform="translate(-7613.635 -249.303)"/>
						</svg>
						<svg id="logo_A" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.119 25.87">
							<path d="M6583.54,272.949a.733.733,0,0,0,.663.42h2.291a.733.733,0,0,0,.669-1.038l-11.185-24.4a.737.737,0,0,0-.67-.429h-1.4a.734.734,0,0,0-.68.455l-10.058,24.4a.734.734,0,0,0,.682,1.012H6573.9a.736.736,0,0,0,.738-.734v-2.129a.736.736,0,0,0-.738-.733h-4.672a.734.734,0,0,1-.682-1.013l5.97-14.338a.174.174,0,0,1,.318-.007Z" transform="translate(-6563.112 -247.499)"/>
						</svg>
						<svg id="logo_D" class="expandOnly" preserveAspectRatio="xMidYMid slice" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.137 25.87">
							<path d="M6676.9,260.434a12.724,12.724,0,0,1-12.46,12.935H6659.5a.735.735,0,0,1-.736-.734v-1.706a.735.735,0,0,1,.736-.733h4.874a8.1,8.1,0,0,0,6.09-2.794,10.6,10.6,0,0,0,0-13.9,8.135,8.135,0,0,0-6.09-2.829H6659.5a.735.735,0,0,1-.736-.734v-1.706a.735.735,0,0,1,.736-.734h4.941A12.746,12.746,0,0,1,6676.9,260.434Z" transform="translate(-6658.765 -247.499)"/>
						</svg>
						<svg id="logo_E" class="expandOnly" preserveAspectRatio="xMidYMid slice" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.275 25.87">
							<path d="M6737.066,247.5h15.8a.735.735,0,0,1,.736.734v1.706a.735.735,0,0,1-.736.734h-11.961a.735.735,0,0,0-.737.734v6.19a.735.735,0,0,0,.737.734h9.5a.735.735,0,0,1,.737.734V260.8a.735.735,0,0,1-.737.734h-9.5a.735.735,0,0,0-.737.734v7.191a.736.736,0,0,0,.737.734h11.961a.735.735,0,0,1,.736.733v1.706a.735.735,0,0,1-.736.734h-15.8a.735.735,0,0,1-.737-.734v-24.4A.735.735,0,0,1,6737.066,247.5Z" transform="translate(-6736.329 -247.499)"/>
						</svg>
						<div class="svgMContainer">
							<svg id="logo_M_full" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27.298 25.87">
								<path d="M6841.831,248.233v24.4a.736.736,0,0,1-.737.734h-2.37a.734.734,0,0,1-.735-.734V254.867a.2.2,0,0,0-.377-.08l-8.146,18.148a.736.736,0,0,1-.672.434h-1.241a.734.734,0,0,1-.663-.414l-11.846-24.4a.734.734,0,0,1,.662-1.053h2.989a.74.74,0,0,1,.671.428l8.479,18.457a.342.342,0,0,0,.624-.006l8.065-18.439a.738.738,0,0,1,.675-.441h3.885A.736.736,0,0,1,6841.831,248.233Zm-26.559,13.479,2.4.009a.734.734,0,0,1,.732.734v10.181a.734.734,0,0,1-.735.734h-2.4a.735.735,0,0,1-.736-.734V262.445A.734.734,0,0,1,6815.272,261.711Z" transform="translate(-6814.533 -247.499)"/>
							</svg>
							<svg id="logo_M_part" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27.298 25.87">
								<path d="M7748.578,268.014l-8.667-18.931a.4.4,0,0,0-.361-.232h-3.761a.4.4,0,0,0-.357.57l12.211,25.246a.4.4,0,0,0,.357.224h1.681a.4.4,0,0,0,.362-.235l8.149-18.221a.256.256,0,0,1,.489.1v17.954a.4.4,0,0,0,.4.4h3.059a.4.4,0,0,0,.4-.4V249.248a.4.4,0,0,0-.4-.4h-4.461a.4.4,0,0,0-.364.238l-8.245,18.92A.268.268,0,0,1,7748.578,268.014Z" transform="translate(-7735.392 -248.851)"/>
							</svg>
						</div>
						<svg id="logo_I" class="expandOnly" preserveAspectRatio="xMidYMid slice" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3.84 25.87">
							<path d="M6928.224,272.635v-24.4a.735.735,0,0,1,.736-.734h2.369a.734.734,0,0,1,.735.734v24.4a.734.734,0,0,1-.735.734h-2.369A.735.735,0,0,1,6928.224,272.635Z" transform="translate(-6928.224 -247.499)"/>
						</svg>
						<svg id="logo_C" class="expandOnly" preserveAspectRatio="xMidYMid slice" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.138 25.87">
							<path d="M6956.936,260.434A12.724,12.724,0,0,1,6969.4,247.5h4.94a.736.736,0,0,1,.737.734v1.706a.735.735,0,0,1-.737.734h-4.872a8.1,8.1,0,0,0-6.091,2.794,10.6,10.6,0,0,0,0,13.9,8.133,8.133,0,0,0,6.091,2.829h4.872a.735.735,0,0,1,.737.733v1.706a.735.735,0,0,1-.737.734h-4.94A12.745,12.745,0,0,1,6956.936,260.434Z" transform="translate(-6956.936 -247.499)"/>
						</svg>
						<svg id="logo_rightChev" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.647 25.87">
							<path d="M7851.573,249.3h-3.33a.281.281,0,0,0-.232.44l8.432,12.343a.4.4,0,0,1,0,.449l-8.427,12.235a.281.281,0,0,0,.232.441h3.471a.4.4,0,0,0,.328-.174l8.471-12.477a.535.535,0,0,0,0-.6l-8.615-12.481A.4.4,0,0,0,7851.573,249.3Z" transform="translate(-7847.962 -249.303)"/>
						</svg>

					</div>
				</router-link>
			</div>
			<div class="burgerMenu" 
					 v-on:click="burgerClick"
					 :class="menuActiveClass">
				<div class="burgerChev1">
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.647 25.87">
						<path d="M7851.573,249.3h-3.33a.281.281,0,0,0-.232.44l8.432,12.343a.4.4,0,0,1,0,.449l-8.427,12.235a.281.281,0,0,0,.232.441h3.471a.4.4,0,0,0,.328-.174l8.471-12.477a.535.535,0,0,0,0-.6l-8.615-12.481A.4.4,0,0,0,7851.573,249.3Z" transform="translate(-7847.962 -249.303)"/>
					</svg>
				</div>
				<div class="burgerChev2">
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.647 25.87">
						<path d="M7851.573,249.3h-3.33a.281.281,0,0,0-.232.44l8.432,12.343a.4.4,0,0,1,0,.449l-8.427,12.235a.281.281,0,0,0,.232.441h3.471a.4.4,0,0,0,.328-.174l8.471-12.477a.535.535,0,0,0,0-.6l-8.615-12.481A.4.4,0,0,0,7851.573,249.3Z" transform="translate(-7847.962 -249.303)"/>
					</svg>
				</div>
			</div>
		</div>
		<div class="headerMidLChev"></div>
		<div class="headerMidRChev"></div>
		<div class="headerRightContainer"
				 :class="menuActiveClass"
				 ref="headerRightContainer">
			<div class="pageAnchors"
					 ref="pageAnchors">
				<router-link
					v-for="anchor in extraVars.anchorPoints"
					v-bind:to="'?anchor=' + anchor.anchorName"
					v-bind:data-anchor="anchor.anchorName"
					v-on:click.native="burgerClick(); anchorClick();">
					{{anchor.anchorTitle}}
				</router-link>
			</div>
			<ul v-if="extraVars.primaryMenu.length"
					class="topPageMenu">
				<li class="menu-item" v-for="menuItem in extraVars.primaryMenu">
					<!--If not a parent / valid link-->
					<router-link 
						v-if="menuItem.url"
						:data-menuID="menuItem.ID" 
						:data-postID="menuItem.object_id"
						:to="menuItem.url"
						v-on:click.native="burgerClick">
						{{ menuItem.title }}
					</router-link>
					
					<!--If a parent-->
					<div v-else class="parent"
						:data-menuID="menuItem.id">
						{{ menuItem.title }}
						
						<router-link
							v-for="childItem in menuItem.children"
							class="child"
							:data-menuID="childItem.ID" 
							:data-postID="childItem.object_id"
							:to="childItem.url"
							ref="childMenuItem"
							v-on:click.native="burgerClick();">> {{ childItem.title }}</router-link>
					</div>
					
				</li>
				<li class="menu-item">
					<router-link
						class="anchor"
						to="/?anchor=get_in_touch"
						v-on:click.native="burgerClick(); anchorClick();">
						Contact
					</router-link>
				</li>
			</ul>		
		</div>
		<div class="headerEndChev"></div>
	</header>
</template>

<script>
import * as globalFunctions from '@/js/globalFunctions.js';
	
export default {
  name: 'headerHandler',
  components: {
  },
  data(){
    return {
			isBurgerActive: false,
			menuActiveClass: '',
			desktopExpandClass: '',
			scrollThrottleTimer: undefined,
    }
  },
  props: {
		extraVars: Object,
  },
  methods: {
    getPathname : function (url) {
			//not required anymore
			var pathname = new URL(url);
			pathname = pathname.pathname;
			return (pathname);
    },
		burgerClick: function () {
			//only do this if in mobile width
			if ( this.extraVars.positionalVars.screenType == 'mobile') {
				this.isBurgerActive = !this.isBurgerActive;
				this.manageBurger();
			}
		},
		anchorClick: function () {
			//scroll down to anchor point when clicked
			var flexcontainer = document.querySelector(".flexContainer"); //always first flexcontainer
			globalFunctions.scrollAnchor(flexcontainer);
		},				
		logoClick: function () {
			//only do this if in mobile width, and menu is already active
			if ((this.extraVars.positionalVars.screenType == 'mobile') && this.isBurgerActive ) {
				this.isBurgerActive = !this.isBurgerActive;
				this.manageBurger();
			}
		},
		manageBurger: function () {
			this.menuActiveClass = this.isBurgerActive ? 'menuActive' : 'menuInactive';
		},
		manageMenuPadding: function (){
			//whenever screen width changes, recalc the menu padding
			var screenWidth = this.extraVars.positionalVars.screenWidth;
			var leftCon = jQuery(this.$refs.headerLeftContainer);
			var rightCon = jQuery(this.$refs.headerRightContainer);
			var calcPad = 0;
			
			if (screenWidth < 1200 ) {
				leftCon.css('padding-left', '1.5rem');
				leftCon.css('padding-right', '1.5rem');
				rightCon.css('padding-left', '1.5rem');
				rightCon.css('padding-right', '1.5rem');
			} else {
				if (screenWidth < 1600 ) {
					calcPad = (screenWidth - 1200) / 2;
				} else {
					calcPad = (screenWidth - 1600) / 2;
				}

				leftCon.css('padding-left', calcPad + 'px');
				rightCon.css('padding-right', calcPad + 'px');
			}
		},
		manageMobileBlobs: function (pos){
			//define top (header + spacing) cutoff point
			var topSpace =  document.querySelector('header .headerLeftContainer').offsetHeight + 100;
			
			//setup blobs for activation
			var blobs = $(this.$refs.pageAnchors).find("a");
			var activeBlob = -1;
			
			//if scroll pos is after anchor pos, light up that anchor blob on menu
			$.each(this.extraVars.anchorPoints, function(index){
				if (pos + topSpace > this.anchorPos) {
					activeBlob = index;
				}
			});

			blobs.removeClass("inFocus");
			if (activeBlob >= 0) {
				$(blobs[activeBlob]).addClass("inFocus");		
			}
		},
		throttleScroll (newPos, oldPos, delay) {
			// If setTimeout is already scheduled, no need to do anything
			if (this.scrollThrottleTimer) {
				return;
			}

			// Schedule a setTimeout after delay seconds
			this.scrollThrottleTimer = setTimeout(() => {
				//add throttled code / function calls here
				
				//check if section areas in focus, to light up nav blob in mobile menu
				if ( 	this.extraVars.positionalVars.screenType == 'mobile' && 
							this.extraVars.anchorPoints.length > 0 ) {
					this.manageMobileBlobs(this.extraVars.flexScrollPos.pos);
				}
				
				//whenever scroll in flex occurs expand or contract header
				if ( this.extraVars.positionalVars.screenType == 'desktop') {
					this.desktopExpandClass = (newPos >= 100 && newPos > oldPos) ? "" : "desktopExpand";
				} 
				
				this.scrollThrottleTimer = undefined;
			}, delay);
		},
  },
	watch: {
		'extraVars.positionalVars.screenType': {
			handler(val) {
				//if going from mobile to desktop, reinitialise all burger bits
				if ( val == 'desktop') {
					this.isBurgerActive = false;
					this.menuActiveClass = '';
					this.showClass = '';
					this.desktopExpandClass = 'desktopExpand'; // also set initial desktop menu to expand
				} else {
					//going to mobile, remove desktopexpand class
					this.desktopExpandClass = ''; // also set initial desktop menu to expand
				}
			},
		},
		'extraVars.positionalVars.screenWidth': {
			handler(val) {
				//whenever screen width changes, recalc the menu padding
				this.manageMenuPadding();
			},
		},
		'extraVars.positionalVars.scrollWidth': {
			handler(val) {
				//resize width of header if scrollbar is present
				jQuery(this.$refs.topHeader).css('width', 'calc(100% - ' + val + 'px');
			},
		},
		'extraVars.flexScrollPos.pos': function(newPos, oldPos) {
			//add unthrottled scroll code here

			//run throttled scroll code
			var delay = 250;
			this.throttleScroll(newPos, oldPos, delay);
		},
	},
	mounted() {
		//detect scroll on fixed header and push to flexContainer
		$(this.$refs.topHeader).bind('mousewheel', function(e){
			var flexContainer = $("#contentContainer .flexContainer").first();
			var pos = flexContainer.scrollTop();
			flexContainer.scrollTop(pos + e.originalEvent.deltaY);
			flexContainer.scrollTop(pos + e.originalEvent.deltaY);
		});
	},
	beforeUpdate() {
	},
	updated() {
		// shitty router does not do nbsp, replace after render
		$.each(this.$refs.childMenuItem, function(i,v){
			v.$el.innerHTML = v.$el.innerHTML.replace(/ /g, '&nbsp;');
		});
	},
}
	
</script>

