<template>
	<section class="flex_07 animateSection" 
					 :data-fcpCount="flexCount"
					 ref="thisSection">
		
		<div class="widthLimit anchorContainer"
				 :class="extraVars.flexiColor.content">
			<h3 class="titleAnchor"
					:class="{'doTitleLine': flexVar.title_line}"
					v-html="flexVar.title_anchor"
					:data-anchor="flexVar.title_anchor | anchorAttr"
					:data-subtitle="flexVar.subtitle_anchor">
			</h3>
			<div class="subtitle"
					 v-html="flexVar.subtitle">
			</div>
		</div>
		
		<div class="areaBelowTitle widthLimit">
			<div class="highlightsContainer">
				<div class="post" v-for="post in postsReqArr.result">
					<router-link 
						class="postBox"
						:data-postID="post.ID" 
						:to="post.pathname"
						v-bind:style="{ 'background-image': 'url(' + post.featured_image + ')' }"
						
						ref="bgImage">
						<div class="overlay"></div>
						<h3 class="postName">{{ post.acf.project_name }}</h3>
						<div class="postDate">{{ post.acf.period }}</div>
						<div class="logoContainer">
							<img class="logo" 
									 v-for="logo in post.acf.logo_repeater.slice(0,3)"
									 v-bind:src="logo.white_logo.url"> <!-- limit to 3 logos -->
						</div>
						<div class="postExcerpt">{{ post.post_excerpt }}</div>
						<div class="ctaContainer">
							<div class="ademicButton">
								<div class="mainBG"></div>
								<div class="hoverBG"></div>
								<div class="btnOuterLeftChev btnOuterChev"></div>
								<div class="btnOuterRightChev btnOuterChev"></div>
								<div class="btnTxt">FIND OUT MORE</div>
							</div>
						</div>
					</router-link>
				</div>
			</div>
			
			<div class="btnContainer">
				<router-link 
					class="ademicButton"
					:class="extraVars.flexiColor.content"
					:to="flexVar.archive_link.url">
					<div class="mainBG"></div>
					<div class="hoverBG"></div>
					<div class="btnOuterLeftChev btnOuterChev"></div>
					<div class="btnOuterRightChev btnOuterChev"></div>
					<div class="btnTxt">{{flexVar.archive_link.title}}</div>
				</router-link>
			</div>
		</div>
		
<!--
		<pre>
			{{ postsReqArr.result }}
		</pre>
-->
		
	</section>
</template>

<script>

import * as globalFunctions from '@/js/globalFunctions.js';
import * as wpEndpoints from '@/js/wpEndpoints.js';
	
export default {
  name: 'flex_07',
  props: {
		flexVar: Object,
		extraVars: Object,
		flexCount: Number,
  },
  data(){
    return {
			parentFlexContainer: '',
			parallaxObjs: [],
			BGColor: '#e4e4e4',
			bgImageArr: this.$refs.bgImage,
      postsReqArr: {
				searchParams: {
					post_type: 'projects',
					posts_per_page: 3,
					category_name: this.flexVar.category.slug,
				},
				result: {},
			},
			scrollThrottleTimer: undefined,
			sectionActive: false,
		}
	},
	computed: {
	},
	mounted(){	
		//set flexContainer to be used within scope of this component
		this.parentFlexContainer = globalFunctions.closestParent(this.$refs.thisSection, '.flexContainer');
		
		//API call for 3 highlight posts
		wpEndpoints.posts_request(this.postsReqArr);
	},
  methods: {
		addParallax(){
			//parallax 1st image, top left to bottom right
			var img1 = this.$refs.bgImage[0].$el;
			var img1BGSize = this.calcBGSize(img1, 20);
			$(img1).css("background-size", img1BGSize + "%");	
			this.parallaxObjs[0] = {
				startScroll: globalFunctions.parallaxStartStop(img1, 0.2).start,
				stopScroll: globalFunctions.parallaxStartStop(img1, 0.2).end,
				element: $(img1),
				cssProp: 'background-position-y',
				cssStart: 0,
				cssStop: 100,
				cssUnit: '%',
			};
			this.parallaxObjs[1] = {
				startScroll: globalFunctions.parallaxStartStop(img1, 0.2).start,
				stopScroll: globalFunctions.parallaxStartStop(img1, 0.2).end,
				element: $(img1),
				cssProp: 'background-position-x',
				cssStart: 0,
				cssStop: 100,
				cssUnit: '%',
			};
			
			//parallax 2nd image, increase by 20%
			var img2 = this.$refs.bgImage[1].$el;
			var img2BGSize = this.calcBGSize(img2, 0);
			this.parallaxObjs[2] = {
				startScroll: globalFunctions.parallaxStartStop(img2, 0.2).start,
				stopScroll: globalFunctions.parallaxStartStop(img2, 0.2).end,
				element: $(img2),
				cssProp: 'background-size',
				cssStart: img2BGSize,
				cssStop: img2BGSize * 1.2,
				cssUnit: '%',
			};

			
			//parallax 3rd image, top right to bottom left
			var img3 = this.$refs.bgImage[2].$el;
			var img3BGSize = this.calcBGSize(img3, 20);
			$(img3).css("background-size", img3BGSize + "%");
			this.parallaxObjs[3] = {
				startScroll: globalFunctions.parallaxStartStop(img3, 0.2).start,
				stopScroll: globalFunctions.parallaxStartStop(img3, 0.2).end,
				element: $(img3),
				cssProp: 'background-position-y',
				cssStart: 0,
				cssStop: 100,
				cssUnit: '%',
			};
			this.parallaxObjs[4] = {
				startScroll: globalFunctions.parallaxStartStop(img3, 0.2).start,
				stopScroll: globalFunctions.parallaxStartStop(img3, 0.2).end,
				element: $(img3),
				cssProp: 'background-position-x',
				cssStart: 100,
				cssStop: 0,
				cssUnit: '%',
			};
			
			
			globalFunctions.parallaxHandler(this.$refs.thisSection, this.parallaxObjs);
		},
		calcBGSize(el, percent){
			//calc background-size percentage based on ratios, for parallaxing
			var bgRatio = $(el).attr("data-bgwidth") / $(el).attr("data-bgheight");
			var containerRatio = $(el).innerWidth() / $(el).innerHeight();
			
			//get correct aspect
			var mainRatio = bgRatio > containerRatio ? bgRatio / containerRatio : containerRatio / bgRatio;
			
			return (percent + 100) * mainRatio;
		},
		throttleScroll (newPos, oldPos, delay) {
			// If setTimeout is already scheduled, no need to do anything
			if (this.scrollThrottleTimer) {
				return;
			}

			// Schedule a setTimeout after delay seconds
			this.scrollThrottleTimer = setTimeout(() => {
				//add throttled code / function calls here

				//check if section in view
				this.sectionActive = globalFunctions.sectionActive(this.$refs.thisSection);
				//send prompt to root to update colors, only when color_swap CMS entry selected
				if(this.sectionActive && this.flexVar.color_swap) {
					this.$root.$emit('flexiColorEvent', {
						bg: this.BGColor,
						content: 'darkContent',
					});
				}
				
				this.scrollThrottleTimer = undefined;
			}, delay);
		},
		
	},
	watch: {
		'extraVars.flexScrollPos.pos': function(newPos, oldPos) {
			//add unthrottled scroll code here
			
			//only start parallax'ing when images are loaded
			if(this.$refs.bgImage){
				this.addParallax(); 
			}
			
			//run throttled scroll code
			var delay = 500;
			this.throttleScroll(newPos, oldPos, delay);
		},
	},
	updated: function () {
		//when DOM re-rendered due to data from API call
		this.$nextTick(() => {
			//add width height as attributes to each of the 3 highlight bg image link elements
			$.each(this.$refs.bgImage, function(){
				globalFunctions.BGImageSize(this.$el);
			});
		})
	},
  created () {
  },
  destroyed () {
  },
}
</script>

<style>	
</style>