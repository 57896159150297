<template>
	<section class="flex_13 animateSection" 
					 :data-fcpCount="flexCount"
					 ref="thisSection">
		<div class="widthLimit">
			<div class="projectsContainer" ref="projContainer">
				<div class="post" v-for="post in postsReqArr.result" ref="posts">
					<router-link 
						class="postBox"
						:data-postID="post.ID" 
						:to="post.pathname">
						<div class="imageContainer">
							<img class="featuredImg" :src="post.featured_image">
						</div>
						<h4 class="postName">{{ post.acf.project_name }}</h4>
						<div class="logoContainer">
							<img class="logo" 
									 v-for="logo in post.acf.logo_repeater.slice(0,3)"
									 v-bind:src="logo.dark_logo.url">  <!--limit to 3 logos -->
						</div>
					</router-link>
				</div>
				<div class="post dummy"></div>
				<div class="post dummy"></div>
				<div class="post dummy"></div>
			</div>
		</div>
	</section>
</template>

<script>

import * as globalFunctions from '@/js/globalFunctions.js';
import * as wpEndpoints from '@/js/wpEndpoints.js';
	
export default {
  name: 'flex_13',
  props: {
		flexVar: Object,
		extraVars: Object,
		flexCount: Number,
  },
  data(){
    return {
			parentFlexContainer: '',
			parallaxObjs: [],
			scrollThrottleTimer: undefined,
      postsReqArr: {
				searchParams: {
					post_type: 'projects',
					posts_per_page: -1,
					category_name: this.flexVar.category.slug,
				},
				result: {},
			},
		}
	},
	computed: {
	},
	mounted(){	
		//set flexContainer to be used within scope of this component
		this.parentFlexContainer = globalFunctions.closestParent(this.$refs.thisSection, '.flexContainer');
		
		//API call for projects posts
		wpEndpoints.posts_request(this.postsReqArr);
	},
  methods: {
		throttleScroll (newPos, oldPos, delay) {
			// If setTimeout is already scheduled, no need to do anything
			if (this.scrollThrottleTimer) {
				return;
			}

			// Schedule a setTimeout after delay seconds
			this.scrollThrottleTimer = setTimeout(() => {
				//add throttled code / function calls here

				
				this.scrollThrottleTimer = undefined;
			}, delay);
		},
		animPos () {
			//animate posts to position
			let posts = this.$refs.posts;
			let projContainer = this.$refs.projContainer;

			if (this.extraVars.positionalVars.screenType == "desktop" && posts) {
				//define width of posts
				
				let postW = (projContainer.offsetWidth / 3) - globalFunctions.remToPixels(3);
				$(posts).width(postW);

				//defined height of featured image based on width
				$(posts).find(".featuredImage").css('height', postW * 0.66);

				//find max height of each row
				let totalRows = Math.ceil(posts.length/3);
				let rowH = new Array(totalRows).fill(0);
				$(posts).each(function(i,val) {
					$(val).height('unset');
					let row = Math.floor(i/3);
					if ($(val).height() > rowH[row]) {
						rowH[row] = $(val).height();
					}
				});

				//add max height of all rows
				$(projContainer).height(rowH.reduce((a, b) => a + b, 0));

				//assign post positions, if transition completed
				if (!this.extraVars.isTransitionClassActive) {
					$(posts).each(function(i,val) {
						let row = Math.floor(i/3);
						$(val).css('height', rowH[row]);
						if (row > 0) {
							$(val).css('top', row * rowH[row - 1]);
						}

						let col = i - (row * 3);
						$(val).css('left', col * (postW + globalFunctions.remToPixels(1.5)));

						$(val).css('opacity', 1);
					});
				}
			
			} else {
				//reset all
				$(posts)
					.css('width', 'unset')
					.css('height', 'unset')
					.css('top', 'unset')
					.css('left', 'unset');
				
				$(projContainer).css('height', 'unset');
				
				$(posts).find(".featuredImage").css('height', 'unset');
			}
		},
	},
	watch: {
		'extraVars.flexScrollPos.pos': function(newPos, oldPos) {
			//add unthrottled scroll code here
			
			//run throttled scroll code
			var delay = 500;
			this.throttleScroll(newPos, oldPos, delay);
		},
		'postsReqArr.result': function(data) {
		//post API call is returned, perform animation, when DOM completes rendering the post items
			this.$nextTick(() => {
				this.animPos();
			});
		},
		'extraVars.positionalVars.screenWidth': function(val) {
			//if screenWidth changes recalc post positions
			this.animPos();
		},
		'extraVars.isTransitionClassActive': function(val) {
			if (!val) {
				this.$nextTick(() => {
					this.animPos();
				});
			}
		},
	},
  created () {
  },
  destroyed () {
  },
}
</script>

<style>	
</style>