<template>
	<section class="flex_04 animateSection" 
					 :data-fcpCount="flexCount"
					 ref="thisSection">
		
		<div class="widthLimit sectionContainer">
			<div class="heroAnchors">
				<router-link
					v-for="anchor in extraVars.anchorPoints"
					class="anchor"
					v-bind:to="'?anchor=' + anchor.anchorName"
					v-on:click.native="anchorClick();">
					<h4>{{anchor.anchorTitle}}</h4>
					<p>{{anchor.anchorSubtitle}}</p>
				</router-link>
			</div>
			<div class="contentContainer">
				<div class="content wysiwyg"
						 v-bind:style="{ 'background-image': 'url(' + flexVar.image.url + ')' }"
						 v-html="flexVar.content">
				</div>
			</div>
		</div>
	</section>
</template>

<script>

import * as globalFunctions from '@/js/globalFunctions.js';
	
export default {
  name: 'flex_04',
  props: {
		flexVar: Object,
		extraVars: Object,
		flexCount: Number,
  },
  data(){
    return {
			parentFlexContainer: '',
			parallaxObjs: [],
			BGColor: '#E4E4E4',
			scrollThrottleTimer: undefined,
			sectionActive: false,
		}
	},
	computed: {
	},
	mounted(){	
		//set flexContainer to be used within scope of this component
		this.parentFlexContainer = globalFunctions.closestParent(this.$refs.thisSection, '.flexContainer');
	},
  methods: {
		throttleScroll (newPos, oldPos, delay) {
			// If setTimeout is already scheduled, no need to do anything
			if (this.scrollThrottleTimer) {
				return;
			}

			// Schedule a setTimeout after delay seconds
			this.scrollThrottleTimer = setTimeout(() => {
				//add throttled code / function calls here

				//check if section in view
				this.sectionActive = globalFunctions.sectionActive(this.$refs.thisSection);
				//send prompt to root to update colors
				if(this.sectionActive) {
					this.setFlexiColor();
				}
				
				this.scrollThrottleTimer = undefined;
			}, delay);
		},
		setFlexiColor: function () {
			//set background & content colors
			this.$root.$emit('flexiColorEvent', {
				bg: this.BGColor,
				content: 'darkContent',
			});
		},
		anchorClick: function () {
			//scroll down to anchor point when clicked
			var flexcontainer = document.querySelector(".flexContainer"); //always first flexcontainer
			globalFunctions.scrollAnchor(flexcontainer);
		},
	},
	watch: {
		'extraVars.flexScrollPos.pos': function(newPos, oldPos) {
			//add unthrottled scroll code here
			
			//run throttled scroll code
			var delay = 500;
			this.throttleScroll(newPos, oldPos, delay);
		},
		'extraVars.isTransitionClassActive':  {
			handler(val) {
				if (!val){
					// after transition, set initial page color
					this.setFlexiColor();
				}
			},
		},
	},
  created () {
  },
  destroyed () {
  },
}
</script>

<style>	
</style>