<template>
	<section class="flex_05 animateSection" 
					 :data-fcpCount="flexCount"
					 ref="thisSection">
		
		<div class="widthLimit anchorContainer"
				 :class="extraVars.flexiColor.content">
			<h3 class="titleAnchor doTitleLine"
					v-html="flexVar.title_anchor"
					:data-anchor="flexVar.title_anchor | anchorAttr"
					:data-subtitle="flexVar.subtitle_anchor">
			</h3>
			<div class="subtitle"
					 v-html="flexVar.subtitle">
			</div>
		</div>
		
		<div class="areaBelowTitle">
			<div class="image"
					 ref="image"
					 v-bind:style="{ 'background-image': 'url(' + flexVar.image.url + ')' }">
			</div>
			<div class="widthLimit contentContainer">
					<div class="content wysiwyg"
							 :class="extraVars.flexiColor.content"> 
						<v-runtime-template :template="'<div>' + flexVar.content + '</div>'"></v-runtime-template>
					</div>
			</div>
			
		</div>
	</section>
</template>

<script>

import * as globalFunctions from '@/js/globalFunctions.js';
import VRuntimeTemplate from "v-runtime-template";
	
export default {
  name: 'flex_05',
  props: {
		flexVar: Object,
		extraVars: Object,
		flexCount: Number,
  },
  data(){
    return {
			parentFlexContainer: '',
			parallaxObjs: [],
			BGColor: '#FFFFFF',
			scrollThrottleTimer: undefined,
			sectionActive: false,
		}
	},
	computed: {
	},
	mounted(){	
		//set flexContainer to be used within scope of this component
		this.parentFlexContainer = globalFunctions.closestParent(this.$refs.thisSection, '.flexContainer');
	
		//add width height as attributes to image container
		globalFunctions.BGImageSize(this.$refs.image);
		
	},
  methods: {
		throttleScroll (newPos, oldPos, delay) {
			// If setTimeout is already scheduled, no need to do anything
			if (this.scrollThrottleTimer) {
				return;
			}

			// Schedule a setTimeout after delay seconds
			this.scrollThrottleTimer = setTimeout(() => {
				//add throttled code / function calls here

				//check if section in view
				this.sectionActive = globalFunctions.sectionActive(this.$refs.thisSection);
				//send prompt to root to update colors
				if(this.sectionActive) {
					this.$root.$emit('flexiColorEvent', {
						bg: this.BGColor,
						content: 'darkContent',
					});
				}
				
				this.scrollThrottleTimer = undefined;
			}, delay);
		},
		
		addParallax () {
			var myImage = this.$refs.image;
			var midPoint = globalFunctions.parallaxStartStop(myImage).start + ( (globalFunctions.parallaxStartStop(myImage).end - globalFunctions.parallaxStartStop(myImage).start) / 2 );
			
			this.parallaxObjs[0] = {
				startScroll: globalFunctions.parallaxStartStop(myImage).start,
				stopScroll: 0.9 * midPoint ,
				element: $(myImage),
				cssProp: '-webkit-filter',
				multi: true,
				subprops: [
					{
						subprop: 'grayscale',
						cssStart: 100,
						cssStop: 0,
						cssUnit: '%',
						updateStart: true,
						updateStop: false,
					},
					{
						subprop: 'blur',
						cssStart: 3,
						cssStop: 0,
						cssUnit: 'px',
						updateStart: true,
						updateStop: false,
					},
				]
			};
			this.parallaxObjs[1] = {
				startScroll: 1.1 * midPoint,
				stopScroll: globalFunctions.parallaxStartStop(myImage).end,
				element: $(myImage),
				cssProp: '-webkit-filter',
				multi: true,
				subprops: [
					{
						subprop: 'grayscale',
						cssStart: 0,
						cssStop: 100,
						cssUnit: '%',
						updateStart: false,
						updateStop: true,
					},
					{
						subprop: 'blur',
						cssStart: 0,
						cssStop: 3,
						cssUnit: 'px',
						updateStart: false,
						updateStop: true,
					},
				]
			};
			this.parallaxObjs[2] = {
				startScroll: globalFunctions.parallaxStartStop(myImage).start,
				stopScroll: globalFunctions.parallaxStartStop(myImage).end,
				element: $(myImage),
				cssProp: 'background-position-y',
				cssStart: 0,
				cssStop: 50,
				cssUnit: '%',
			};
			this.parallaxObjs[3] = {
				startScroll: globalFunctions.parallaxStartStop(myImage).start,
				stopScroll: globalFunctions.parallaxStartStop(myImage).end,
				element: $(myImage),
				cssProp: 'background-position-x',
				cssStart: 0,
				cssStop: 100,
				cssUnit: '%',
			};
			
			//replace bg-size cover functionality by determining if it should be 100% width or height
			var parentRatio = $(myImage).outerWidth() / $(myImage).outerHeight(); 
			var BGRatio = $(myImage).attr('data-bgWidth') / $(myImage).attr('data-bgHeight');
			
			if (parentRatio <= BGRatio) {
				this.parallaxObjs[4] = {
					startScroll: globalFunctions.parallaxStartStop(myImage).start,
					stopScroll: globalFunctions.parallaxStartStop(myImage).end,
					element: $(myImage),
					cssProp: 'background-size',
					cssStart: 115,
					cssStop: 100,
					cssUnit: '%',
					prefix: 'auto ',
				};
			} else {
				this.parallaxObjs[4] = {
					startScroll: globalFunctions.parallaxStartStop(myImage).start,
					stopScroll: globalFunctions.parallaxStartStop(myImage).end,
					element: $(myImage),
					cssProp: 'background-size',
					cssStart: 115,
					cssStop: 100,
					cssUnit: '%',
					suffix: ' auto',
				};
			}
			
			globalFunctions.parallaxHandler(this.$refs.thisSection, this.parallaxObjs);
		}
	},
	watch: {
		'extraVars.flexScrollPos.pos': function(newPos, oldPos) {
			//add unthrottled scroll code here

			//manage parallax
			this.addParallax(); 
			
			//run throttled scroll code
			var delay = 500;
			this.throttleScroll(newPos, oldPos, delay);
		},
	},
  components: {
    VRuntimeTemplate,
  },
  created () {
  },
  destroyed () {
  }
}
</script>

<style>	
</style>