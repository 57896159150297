import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router';

global.jQuery = require('jquery');
var $ = global.jQuery;
window.$ = $;

VanillaTilt = require('vanilla-tilt');

Vue.config.productionTip = false;

//global filters
Vue.filter('anchorAttr', function (value) {
	//format anchor attribute text
	if (!value) return '';
	value = value.toString();
	return value.replace(/ /g, "_").toLowerCase(); 
})

//global variable / mixins
//Vue.mixin({
//  data: function() {
//    return {
//			globalVar: "this is global",
//    }
//  }
//})

Vue.use(VueRouter);

const router = new VueRouter({
	mode: 'history',
	routes: [],
});

new Vue({
	router,
  render: h => h(App),
}).$mount('#app');