import * as globalFunctions from './globalFunctions.js';

const wp_rest = 'https://stagecms.ademic.co.uk/wp-json/ademic/v1/';
const ademic_rest = 'https://stagecms.ademic.co.uk/wp-json/ademic/v1/';

export function menu_navs (appThis) {
	fetch(ademic_rest + 'menu-navs')
		.then(stream => stream.json())
		.then(data => {
			appThis.appVar.menuSection = data;
		});
}

export function posts_index (appThis) {
	fetch(ademic_rest + 'posts-index')
		.then(stream => stream.json())
		.then(data => {
			appThis.appVar.postsIndex = data;
			//only run at the page init, so once index available, match against current path and pull data
			route_post_acf (appThis);
		});
}

export function route_post_acf (appThis) {
	var i; 
	var post_id = -1;
	for (i = 0; i < appThis.appVar.postsIndex.length; ++i) {
//		console.log(appThis.appVar.postsIndex[i].post_permalink);
		if ( 
				appThis.appVar.postsIndex[i].post_permalink == appThis.$route.path || 
				appThis.appVar.postsIndex[i].post_permalink == appThis.$route.path + '/' //for path missing slash
			 ) {
			post_id = appThis.appVar.postsIndex[i].ID;
		}
	}
	
	if ( post_id > 0 ) {
		// get post data including ACF
		const fetchOptions = {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json;charset=UTF-8'
			},
			body: JSON.stringify({
				post_ID: post_id,
			})
		}; 
		
		fetch(ademic_rest + 'route-post-acf', fetchOptions)
			.then(stream => stream.json())
			.then(data => {
				appThis.appVar.contentSection = data;
				appThis.reRenderContent++; //force flex area reRender
			});
	} else {
		// redirect to home page
		window.location.href = '/';
	}
	
}

export function homepage_acf (appThis) {
	fetch(ademic_rest + 'homepage-acf')
		.then(stream => stream.json())
		.then(data => {
			appThis.appVar.contentSection = data;
		});
}

// send post query to CMS and get result
export function posts_request (postArr) {
	const fetchOptions = {
		method: 'POST',
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json;charset=UTF-8'
		},
		body: JSON.stringify({
			searchParams: postArr.searchParams,
		})
	};

	fetch(ademic_rest + 'posts-request', fetchOptions)
		.then(stream => stream.json())
		.then(data => {
			postArr.result = data;
			$.each(postArr.result, function (ind, val){
				val.pathname = getURL(val.url).pathname + getURL(val.url).search;
			});
		});
}

// contact form submission
export function submit_contact_form (fields, flexData) {
	const fetchOptions = {
		method: 'POST',
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json;charset=UTF-8'
		},
		body: JSON.stringify({
			fields: fields,
		})
	};

	fetch(ademic_rest + 'submit-form', fetchOptions)
		.then(stream => stream.json())
		.then(data => {
			flexData.feedback = JSON.parse(data);
		});
}

//get URL parts from a string
export function getURL(href) {
    var url = document.createElement("a");
    url.href = href;
    return { 
			protocol: url.protocol,
			host: url.host,
			pathname: url.pathname,
			search: url.search,
		};
}

/*testing below*/
export function posts_acf (appThis) {
	fetch(ademic_rest + 'posts-acf')
		.then(stream => stream.json())
		.then(data => {
			appThis.appVar.testingVar = data;
		});
}

export function wp_posts (appThis) {
	fetch('https://stagecms.ademic.co.uk/wp-json/wp/v2/posts')
		.then(response => response.json().then(json => ({
			totalPages: response.headers.get("x-wp-totalpages"),
			totalHits: response.headers.get("x-wp-total"),
			json
		})))
		.then(result => {
			appThis.appVar.testingVar = result.json;
		});
}
