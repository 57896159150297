<template>
	<section class="flex_06 animateSection" 
					 :data-fcpCount="flexCount"
					 ref="thisSection">
		
		<div class="widthLimit anchorContainer"
				 :class="extraVars.flexiColor.content">
			<h3 class="titleAnchor doTitleLine"
					v-html="flexVar.title_anchor"
					:data-anchor="flexVar.title_anchor | anchorAttr"
					:data-subtitle="flexVar.subtitle_anchor">
			</h3>
			<div class="subtitle"
					 v-html="flexVar.subtitle">
			</div>
		</div>
		
		<div class="areaBelowTitle widthLimit">
			<div class="logoContainer"
					 :class="extraVars.flexiColor.content">
				<div class="logo" v-for="logo in flexVar.logo_repeater">
					<img class="lightLogo" v-bind:src="logo.light_logo.url">
					<img class="darkLogo" v-bind:src="logo.dark_logo.url">
				</div>
			</div>
			<div class="cta">
				<router-link 
					class="ademicButton"
					:class="extraVars.flexiColor.content"
					:to="flexVar.cta.url">
					<div class="mainBG"></div>
					<div class="hoverBG"></div>
					<div class="btnOuterLeftChev btnOuterChev"></div>
					<div class="btnOuterRightChev btnOuterChev"></div>
					<div class="btnTxt">{{ flexVar.cta.title }}</div>
				</router-link>
			</div>
				
		</div>
	</section>
</template>

<script>

import * as globalFunctions from '@/js/globalFunctions.js';
	
export default {
  name: 'flex_06',
  props: {
		flexVar: Object,
		extraVars: Object,
		flexCount: Number,
  },
  data(){
    return {
			parentFlexContainer: '',
			parallaxObjs: [],
			BGColor: '#2C2E3D',
			scrollThrottleTimer: undefined,
			sectionActive: false,
		}
	},
	computed: {
	},
	mounted(){	
		//set flexContainer to be used within scope of this component
		this.parentFlexContainer = globalFunctions.closestParent(this.$refs.thisSection, '.flexContainer');
		
		//if flexContainer exists then add scrollHandler
		if (this.parentFlexContainer) {
			(this.parentFlexContainer).addEventListener('scroll', this.handleScroll);
		}
	},
  methods: {
		throttleScroll (newPos, oldPos, delay) {
			// If setTimeout is already scheduled, no need to do anything
			if (this.scrollThrottleTimer) {
				return;
			}

			// Schedule a setTimeout after delay seconds
			this.scrollThrottleTimer = setTimeout(() => {
				//add throttled code / function calls here

				//check if section in view
				this.sectionActive = globalFunctions.sectionActive(this.$refs.thisSection);
				//send prompt to root to update colors
				if(this.sectionActive) {
					this.$root.$emit('flexiColorEvent', {
						bg: this.BGColor,
						content: 'lightContent',
					});
				}
				
				this.scrollThrottleTimer = undefined;
			}, delay);
		},
	},
	watch: {
		'extraVars.flexScrollPos.pos': function(newPos, oldPos) {
			//add unthrottled scroll code here
			
			//run throttled scroll code
			var delay = 500;
			this.throttleScroll(newPos, oldPos, delay);
		},
	},
  created () {
  },
  destroyed () {
  },
}
</script>

<style>	
</style>