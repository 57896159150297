<template>
	<section class="flex_11 animateSection"
					 :class="[extraVars.flexiColor.content, flexVar.orientation]"
					 :data-fcpCount="flexCount"
					 :style="customCSS"
					 ref="thisSection">

		<!--	For mobile, title header needs to be above the media	-->
		<div v-if="extraVars.positionalVars.screenType === 'mobile'"
				 class="widthLimit contentContainer"
				 :class="[flexVar.type]">
			<div class="anchorContainer">
				<h3 class="titleAnchor"
						v-html="flexVar.title_anchor"
						:data-anchor="flexVar.title_anchor | anchorAttr"
						:data-subtitle="flexVar.subtitle_anchor">
				</h3>
			</div>
		</div>
		
		<div class="media">
			<div v-if="flexVar.media_type === 'image'" 
					 class="image"
					 ref="image"
					 v-bind:style="{ 'background-image': 'url(' + flexVar.image.url + ')' }">
			</div>
			
			<video v-if="flexVar.media_type === 'video'" 
						 class="video" ref="video" autoplay controls loop playsinline muted>
				<source v-bind:src="flexVar.image.url" 
								type="video/mp4">
				Your browser does not support the video tag.
			</video>
			
			<div v-if="flexVar.media_type === 'laptop'"
					 class="laptop accelerateGPU"
					 ref="laptop">
				<div class="laptopContainer accelerateGPU"
						 ref="laptopContainer">
					<div class="laptopScreen accelerateGPU"
							 ref="laptopScreen">
						<img class="screenImg"
								 src="@/icons/laptopScreen.png"
								 ref="screenImg">
						<div class="laptopImage"
								 ref="laptopImage"
								 v-bind:style="{ 'background-image': 'url(' + flexVar.image.url + ')' }">
						</div>
					</div>
					<div class="laptopCoverContainer accelerateGPU"
							 ref="laptopCoverContainer">
						<div class="laptopCover"
								 ref="laptopCover">
							<img src="@/icons/laptopCover.png">
						</div>
					</div>
					<div class="laptopBottom accelerateGPU"
							 ref="laptopBottom">
						<img src="@/icons/laptopBottom.png"
								 @load="onLaptopLoad">
					</div>
				</div>
			</div>
			
			<div v-if="flexVar.media_type === 'mobile'" class="phoneContainer"
					 ref="phoneContainer">
				<div class="phoneFront accelerateGPU">
					<svg enable-background="new 0 0 1041 2136" viewBox="0 0 1041 2136" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="phoneMask">
						<mask id="thisMask">
							<rect fill="#fff" height="100%" width="100%"/>
							<path d="m40.2 92.9v1941.9s18 68.7 75.2 68.7 801 0 801 0 73.6-14.7 73.6-86.6 0-1924 0-1924-7.6-82.3-80.1-82.3-771.5 0-771.5 0-75.9-9.8-98.2 82.3z"/>
						</mask>
						<rect :fill="extraVars.flexiColor.bg" height="100%" mask="url(#thisMask)" width="100%"/>
					</svg>
					<img class="phoneFrame" src="@/icons/flex02_phoneFront.png" 
							 ref="phoneFrame" @load="extendLine(), defineMobileHeight()">
					<div class="imageContainer"
							 ref="imageContainer">
						<img class="phoneHeader" 
								 v-bind:src="flexVar.phone_header.url"
								 ref="phoneHeader">
						<img class="phoneScroll accelerateGPU" 
								 v-bind:src="flexVar.image.url"
								 ref="phoneScroll">
					</div>
				</div>
				<div class="phoneSide">
					<img class="phoneFrame" src="@/icons/flex02_phoneSide.png">
				</div>
				<div class="phoneBack">
					<img class="phoneFrame" src="@/icons/flex02_phoneBack.png">
				</div>
			</div>
		</div>
		
		<div class="widthLimit contentContainer"
				 :class="[flexVar.type]"
				 ref="contentContainer">
			<div v-if="extraVars.positionalVars.screenType === 'desktop'"
					 class="anchorContainer">
				<h3 class="titleAnchor"
						v-html="flexVar.title_anchor"
						:data-anchor="flexVar.title_anchor | anchorAttr"
						:data-subtitle="flexVar.subtitle_anchor">
				</h3>
			</div>
			<div class="content"
					 v-html="flexVar.content">
			</div>
			<div class="logoContainer"
					 ref="logoContainer">
				<img class="logo" 
						 v-for="logo in flexVar.logos"
						 v-bind:src="logo.url"
						 @load="extendLine">
			</div>
			
		</div>
		
		<div class="arrowDown">
			<svg viewBox="0 0 28.989 20.398" xmlns="http://www.w3.org/2000/svg">
				<g transform="translate(44.531 -41.775) rotate(90)">
					<path transform="translate(-187.99 -228.12)" d="M239.817,272.154h-9.131a.414.414,0,0,1-.341-.648l8.668-12.958a1.307,1.307,0,0,0,0-1.479l-8.671-12.257a.414.414,0,0,1,.341-.647h8.772a1.307,1.307,0,0,1,1.076.565l8.86,12.208a1.307,1.307,0,0,1,.006,1.477l-8.5,13.166A1.307,1.307,0,0,1,239.817,272.154Z" stroke-miterlimit="10"/>
				</g>
			</svg>
			<div class="line" ref="arrowDownLine"></div>
		</div>
		
	</section>
</template>

<script>

import * as globalFunctions from '@/js/globalFunctions.js';
	
export default {
  name: 'flex_11',
  props: {
		flexVar: Object,
		extraVars: Object,
		flexCount: Number,
  },
  data(){
    return {
			parentFlexContainer: '',
			parallaxObjs: [],
			scrollThrottleTimer: undefined,
			arrowLineOrigHeight: 0,
		}
	},
	computed: {
		customCSS: function() {
			if ( this.extraVars.positionalVars.screenType == 'mobile') {
				return this.flexVar.custom_css_group.custom_css_mobile;
			} else {
				return this.flexVar.custom_css_group.custom_css_desktop;
			}
		},
	},
	mounted(){	
		//set flexContainer to be used within scope of this component
		this.parentFlexContainer = globalFunctions.closestParent(this.$refs.thisSection, '.flexContainer');
		
		//record original arrow line height
		this.arrowLineOrigHeight = this.$refs.arrowDownLine.clientHeight;
		
		//mobile phone tilt
		if (this.$refs.phoneContainer) {
			VanillaTilt.init(this.$refs.phoneContainer, {
				max: 4,
				speed: 2000,
				scale: 1.02,
				reverse: true,
			});
		}
		
		//add native height / width info as attributes to image div, which kicks off max-height limit calcs
		if (this.$refs.image) {
			globalFunctions.BGImageSize(this.$refs.image);
		}
		
		//define video height
		if (this.$refs.video) {
			this.defineVidHeight();
		}
//		console.log(this.flexVar);
	},
  methods: {
		throttleScroll (newPos, oldPos, delay) {
			// If setTimeout is already scheduled, no need to do anything
			if (this.scrollThrottleTimer) {
				return;
			}

			// Schedule a setTimeout after delay seconds
			this.scrollThrottleTimer = setTimeout(() => {
				//add throttled code / function calls here
				
				//check image heights are ok
			if (this.$refs.image) {
				this.defineImgHeight();
			}
				
				// Handle laptop opening on scroll
				if (this.$refs.laptopContainer){
					var startTop = -0.8 * this.$refs.laptopContainer.clientHeight;
					if ( (this.extraVars.flexScrollPos.pos < 
							globalFunctions.parallaxStartStop(this.$refs.laptop, 0.3).start) ||
							this.extraVars.flexScrollPos.pos < 20 ) {
						if ( this.$refs.laptopContainer.classList.contains("flipOpen") ) {
							this.$refs.laptopContainer.classList.add("flipClose");
							this.$refs.laptopContainer.classList.remove("flipOpen");
							this.$refs.laptopContainer.style.top = startTop + 'px';
						}
					} else if ( this.extraVars.flexScrollPos.pos >= 
							globalFunctions.parallaxStartStop(this.$refs.laptop, 0.3).start	) {
						this.$refs.laptopContainer.classList.add("flipOpen");
						this.$refs.laptopContainer.classList.remove("flipClose");
						this.$refs.laptopContainer.style.top = 0;
					}
				}
				
				// Handle flipping phone on scroll
				if ( this.$refs.phoneContainer ) {
					if ( 
						this.extraVars.flexScrollPos.pos >= 
						globalFunctions.parallaxStartStop(this.$refs.phoneContainer, 0.3).start && 
						this.extraVars.flexScrollPos.pos < 
						globalFunctions.parallaxStartStop(this.$refs.phoneContainer, 0.3).end
					) {
						this.$refs.phoneContainer.classList.add("flipFront");
						this.$refs.phoneContainer.classList.remove("flipBack");
					} else if ( 
						( this.extraVars.flexScrollPos.pos < 
							globalFunctions.parallaxStartStop(this.$refs.phoneContainer, 0.3).start || 
							this.extraVars.flexScrollPos.pos >= 
							globalFunctions.parallaxStartStop(this.$refs.phoneContainer, 0.3).end 
						 ) &&
						this.$refs.phoneContainer.classList.contains("flipFront")
					) {
						this.$refs.phoneContainer.classList.add("flipBack");
						this.$refs.phoneContainer.classList.remove("flipFront");
					}
				}
				
				
				this.scrollThrottleTimer = undefined;
			}, delay);
		},
		addParallax () {
			if (this.flexVar.media_type === 'image' && this.flexVar.image_parallax) {
				var myImage = this.$refs.image;
				
				//create parallax for each entry			
				for (var [ind, val] of this.flexVar.image_parallax.entries()) {
					this.parallaxObjs[ind] = {
						startScroll: globalFunctions.parallaxStartStop(myImage, parseFloat(val.start_scroll)).start,
						stopScroll: globalFunctions.parallaxStartStop(myImage, parseFloat(val.stop_scroll)).end,
						element: $(myImage),
						cssProp: val.css_property,
						cssStart: parseFloat(val.css_start),
						cssStop: parseFloat(val.css_stop),
						cssUnit: val.css_unit,
						prefix: val.prefix,
						suffix: val.suffix,
					};	
				}
				
			} else if (this.flexVar.media_type === 'laptop') {
				var myImage = this.$refs.laptopImage;
				this.parallaxObjs[0] = {
					startScroll: globalFunctions.parallaxStartStop(myImage, 0.4).start,
					stopScroll: globalFunctions.parallaxStartStop(myImage, 0.2).end,
					element: $(myImage),
					cssProp: 'background-position-y',
					cssStart: 0,
					cssStop: 100,
					cssUnit: '%',
				};
			} else if (this.flexVar.media_type === 'mobile') {
				var phoneScroll = this.$refs.phoneScroll;
				var phoneContainer = this.$refs.phoneContainer;
				var imageContainer = this.$refs.imageContainer;
				var phoneHeader = this.$refs.phoneHeader;
				var stopPoint = $(phoneContainer).height() - $(phoneScroll).innerHeight() - $(phoneHeader).innerHeight() - parseInt($(imageContainer).css('padding-top'));

				this.parallaxObjs[0] = {
					startScroll: globalFunctions.parallaxStartStop(phoneContainer, 0.4).start,
					stopScroll: globalFunctions.parallaxStartStop(phoneContainer, 0.4).end,
					element: $(phoneScroll),
					cssProp: 'top',
					cssStart: 0,
					cssStop: stopPoint,
					cssUnit: 'px',
				};	
			}

			globalFunctions.parallaxHandler(this.$refs.thisSection, this.parallaxObjs);
		},
		extendLine () {
			var addHeight;
			var logoContainerH = this.$refs.logoContainer.clientHeight;
			
			//if mobile, then calc additional mobile height
			if (this.flexVar.media_type === 'mobile') {
				var mobileH = this.$refs.phoneFrame.clientHeight;
				var contentH = this.$refs.contentContainer.clientHeight;
				addHeight = mobileH - contentH - this.arrowLineOrigHeight;
			} else {
				//if not mobile add logo container height to arrow down
				addHeight = logoContainerH;
			}

			this.$refs.arrowDownLine.style.height = this.arrowLineOrigHeight + addHeight + "px";
    },
		defineMobileHeight () {
			//set the phoneFrame to the height of its internals
			var mobileH = this.$refs.phoneFrame.clientHeight;
			this.$refs.phoneContainer.style.height = mobileH - 1 + 'px'; // minus 1 rounding errors
    },
		onLaptopLoad () {
			//set laptop initial top offset
			var startTop = -0.8 * this.$refs.laptopContainer.clientHeight;
			this.$refs.laptopContainer.style.top = startTop + 'px';
    },
		defineImgHeight () {
			//define image height based on percentage of full height, for desktop
			let myImage = this.$refs.image;
			let ratio = myImage.getAttribute('data-bgwidth') / myImage.getAttribute('data-bgheight');
			let fullH = myImage.offsetWidth / ratio;
			
			if (this.extraVars.positionalVars.screenType == "desktop") {
				myImage.style.height = (fullH * parseFloat(this.flexVar.image_height) / 100) + 'px';
			} else {
				//for mobile, define height relative to viewport height
				if (this.flexVar.image_height_mobile) {
					myImage.style.height = (parseFloat(this.flexVar.image_height_mobile)) + 'vh';	
				} else {
					myImage.style.height = "inherit";
				}
			}			
		},
		defineVidHeight () {
			//define video height based on available width to maintain aspect ratio
			let myVid = this.$refs.video;
			let ratio = this.flexVar.image.width / this.flexVar.image.height;
			let fullH = myVid.offsetWidth / ratio;
			
			if (this.extraVars.positionalVars.screenType == "desktop") {
				myVid.style.height = fullH + 'px';	
			} else {
				myVid.style.height = "inherit";
			}
		},
	},
	watch: {
		'extraVars.flexScrollPos.pos': function(newPos, oldPos) {
			//add unthrottled scroll code here
			//manage parallax
			this.addParallax(); 
			
			//run throttled scroll code
			var delay = 500;
			this.throttleScroll(newPos, oldPos, delay);
		},
		'extraVars.positionalVars.screenWidth': function(newPos, oldPos) {
			//if screenWidth changes, recalc phoneFrame height if required
			if (this.$refs.phoneContainer) {
				this.defineMobileHeight();
			}
			
			//add native height / width info as attributes to image div, which kicks off max-height limit calcs
			if (this.$refs.image) {
				globalFunctions.BGImageSize(this.$refs.image);
			}
			
			//define video height
			if (this.$refs.video) {
				this.defineVidHeight();
			}
			
			this.extendLine();
		},
	},
	updated: function () {
		//when DOM re-rendered due to attribute data-bgheight / width being added
		this.$nextTick(() => {
			//define image height based on percentage of full height
			if (this.$refs.image) {
				this.defineImgHeight();
			}
		})
	},
  created () {
  },
  destroyed () {
  }
}
</script>

<style>	
</style>