<template>
	<div class="bigChevContainer"
			 ref="bigChevContainer">
		<div class="animateSection leftNavChev"
				 ref="leftNavChev">
			<div class="svgContainer">
				<svg enable-background="new 0 0 355.3 723" viewBox="0 0 355.3 723" xmlns="http://www.w3.org/2000/svg">
					<path d="m248.6 0h100.4c3.5 0 6.3 2.8 6.3 6.3 0 1.3-.4 2.5-1.1 3.6l-237.3 344.4c-2.4 3.4-2.4 8 0 11.4l237.3 347.4c2 2.9 1.2 6.8-1.6 8.8-1 .7-2.3 1.1-3.6 1.1h-96.3c-3.3 0-6.4-1.6-8.3-4.4l-242.6-351.4c-2.4-3.4-2.4-8 0-11.4l238.5-351.4c1.9-2.7 5-4.4 8.3-4.4z"/>
				</svg>
			</div>
			<div class="blobContainer"
					 ref="pageAnchors">
				<router-link
					v-for="(anchor, index) in extraVars.anchorPoints"
					v-bind:data-pos="index"			
					class="anchor cursorNoExpand"			 
					v-bind:to="'?anchor=' + anchor.anchorName"
					v-on:click.native="anchorClick();">
					<h6 class="linkText">{{anchor.anchorTitle}}</h6>
				</router-link>
				<div class="titleLine"
						 :class="[extraVars.flexiColor.content]"
						 v-bind:style="{ 'width': titleLineWidth + 'px' }">
				</div>
			</div>
		</div>
		<div class="animateSection rightChev flexiColor"
				 :class="extraVars.flexiColor.content"
				 ref="rightChev">
			<div class="svgContainer">
				<svg enable-background="new 0 0 243.4 490.7" viewBox="0 0 243.4 490.7" xmlns="http://www.w3.org/2000/svg">
					<path d="m73.9 488.7h-67.5c-2.3 0-4.2-1.9-4.2-4.2 0-.9.3-1.7.7-2.4l159.7-231.8c1.6-2.3 1.6-5.4 0-7.7l-159.8-234c-1.3-1.9-.8-4.5 1.1-5.9.7-.4 1.6-.7 2.4-.7h64.8c2.2 0 4.3 1.1 5.6 2.9l163.3 236.6c1.6 2.3 1.6 5.4 0 7.7l-160.5 236.5c-1.2 1.9-3.4 3-5.6 3z" fill="none" stroke-width="4"/>
				</svg>
			</div>
		</div> 
		
	</div>

</template>

<script>
	
import * as globalFunctions from '@/js/globalFunctions.js';

export default {
  name: 'navChevron',
  components: {
  },
  data(){
    return {
			parentFlexContainer: '',
			parallaxObjs: [],
			currentNavPos: 0,
			targetNavPos: 0,
			titleLineWidth: 0,
			anchorCoor: [
				//initial coordinates, auto updated based on number of anchor points
				{pos: -7, top: -10, left: 20.4 },
				{pos: -6, top: -6, left: 17.7 },
				{pos: -5, top: -2, left: 15 },
				{pos: -4, top: 2, left: 12.3 },
				{pos: -3, top: 6, left: 9.6 },
				{pos: -2, top: 10, left: 6.9 },
				{pos: -1, top: 14, left: 4.2},
				{pos: 0, top: 18, left: 1.5 },
				{pos: 1, top: 22, left: 4.2 },
				{pos: 2, top: 26, left: 6.9 },
				{pos: 3, top: 30, left: 9.6 },
				{pos: 4, top: -100, left: 12.3 },
				{pos: 5, top: -100, left: 15 },
				{pos: 6, top: -100, left: 17.7 },
				{pos: 7, top: -100, left: 20.4 },
			],
    }
  },
  props: {
		extraVars: Object,
  },
  methods: {
		throttleScroll (newPos, oldPos, delay) {
			// If setTimeout is already scheduled, no need to do anything
			if (this.scrollThrottleTimer) {
				return;
			}

			// Schedule a setTimeout after delay seconds
			this.scrollThrottleTimer = setTimeout(() => {
				//add throttled code / function calls here
				
				//manage blob movement around left navigational chevron
				if (	this.extraVars.positionalVars.screenType == 'desktop' && 
							this.extraVars.anchorPoints.length > 0 ) {
					this.manageBlobs(this.extraVars.flexScrollPos.pos);
				}
				
				this.scrollThrottleTimer = undefined;
			}, delay);
		},
		addParallax () {
			var leftNavChev = $(this.$refs.leftNavChev);
			var startTopL = window.innerHeight - leftNavChev.outerHeight(true);
			this.parallaxObjs[0] = {
				startScroll: 0,
				stopScroll: 0.9 * window.innerHeight,
				element: leftNavChev,
				cssProp: 'top',
				cssStart: startTopL,
				cssStop: this.extraVars.positionalVars.scrollHeight,
				cssUnit: 'px',
			};
			
			var rightChev = $(this.$refs.rightChev);
			var stopTopR = window.innerHeight - rightChev.outerHeight(true) + this.extraVars.positionalVars.scrollHeight;
			this.parallaxObjs[1] = {
				startScroll: 0,
				stopScroll: window.innerHeight,
				element: rightChev,
				cssProp: 'top',
				cssStart: 0,
				cssStop: stopTopR,
				cssUnit: 'px',
			};
			
			globalFunctions.parallaxHandler(this.$refs.bigChevContainer, this.parallaxObjs);
		},
		anchorClick: function () {
			//scroll down to anchor point when clicked
			var flexcontainer = document.querySelector(".flexContainer"); //always first flexcontainer
			globalFunctions.scrollAnchor(flexcontainer);
		},
		manageBlobs: function (pos){
			//define top cutoff point - central point of top left chevron
			var topSpace =  document.querySelector('.bigChevContainer .leftNavChev').offsetHeight / 2;
			
			//setup blobs for activation
			var blobs = $(this.$refs.pageAnchors).find("a");
			var activeBlob = 0; //default pos
			
			//determine active blob, and title focus for animation 
			var localWidth = 0;
			$.each(this.extraVars.anchorPoints, function(index){
				//last item with scroll pos after its anchor pos is the active item
				if (pos + topSpace + 100 > this.anchorPos) {
					activeBlob = index;
				}
				
				//if within title focus area, apply required classes
				var tolerance = globalFunctions.remToPixels(2);
				var anchorHeight = $(".titleAnchor").first().height() / 2; //half height of first anchor
				var anchorLeft = $(".anchorContainer").first().offset().left - globalFunctions.remToPixels(4); //distance of first anchor to left of screen
				
				if (pos + topSpace - anchorHeight < this.anchorPos + tolerance && 
					 	pos + topSpace - anchorHeight > this.anchorPos - tolerance) {

					//only show Title Line if required by class
					if (this.anchorLine) {
						// make titleLine visible by calculating distance of first anchor to left of screen
						localWidth = $(".anchorContainer").first().offset().left - globalFunctions.remToPixels(4);
					}
					$(blobs[index]).addClass("inFocus");
				} else {
					$(blobs[index]).removeClass("inFocus");
				}
			});
			this.titleLineWidth = localWidth;	
			
			//move blobs to correct positions, if required
			this.targetNavPos = activeBlob;
			if (this.targetNavPos != this.currentNavPos) {
				this.moveBlobs();
			}
			
		},
		moveBlobs () {
			// If movement in motion, no need to do anything
			if (this.moveBlobTimer) {
				return;
			}
			
			//do movement
			var direction = this.targetNavPos > this.currentNavPos ? -1 : 1;
			var blobs = $(this.$refs.pageAnchors).find(".anchor");
			$.each(blobs, function(index){
				var curPos = parseInt($(this).attr("data-pos"));
				$(this).attr("data-pos", curPos + direction );
			});
			this.setBlobPos();
			
			//add delay to allow for movement, then update current pos and reset timer
			var delay = 250;
			this.moveBlobTimer = setTimeout(() => {
				
				this.currentNavPos = this.currentNavPos - direction;
				this.moveBlobTimer = undefined;
				
				//if final position not reached, run this again
				if (this.targetNavPos != this.currentNavPos) {
					this.moveBlobs();
				}
			}, delay);
		},
		setBlobPos () {
			//assign styles to each blob based on data-pos attribute
			var blobs = $(this.$refs.pageAnchors).find(".anchor");
			var anchorCoor = this.anchorCoor;
			$.each(blobs, function(index){
				var curPos = parseInt($(this).attr("data-pos"));
				var myStyle = anchorCoor.filter(obj => { return obj.pos === curPos })[0];
				$(this).css({"top": myStyle.top + "rem", "left": myStyle.left + "rem"});
			});
		},
		defBlobPos () {
			//if larger than default 4 anchor points, then recalc spread to fit
			let qty = this.extraVars.anchorPoints.length;
			if (qty > 4) {
				let topRange = 12;
				let topStart = 18;
				let leftRange = 8.1;
				let leftStart = 1.5;
				let topDiv = topRange / (qty - 1);
				let leftDiv = leftRange / (qty - 1);
				for (var myObj of this.anchorCoor) {
					myObj.top = topStart + (myObj.pos * topDiv);
					myObj.left = leftStart + Math.abs(myObj.pos * leftDiv);
				}
			}
			this.setBlobPos();
		},
  },
	watch: {
		'extraVars.positionalVars.screenWidth': {
			handler(val) {
			},
		},
		'extraVars.flexScrollPos.pos': function(newPos, oldPos) {
			//add unthrottled scroll code here

			//manage positon of chevron relative to scroll, only when home-page and desktop
			if (this.extraVars.positionalVars.screenType == "desktop" && $("#app").attr('data-page') == 'home-page') {
				this.addParallax(); 
			}
			
			//run throttled scroll code
			var delay = 250;
			this.throttleScroll(newPos, oldPos, delay);
		},
	},
	mounted() {
		//set flexContainer to be used within scope of this component
		this.parentFlexContainer = globalFunctions.closestParent(this.$refs.bigChevContainer, '.flexContainer');
		
		//detect scroll on fixed item and push to flexContainer
			globalFunctions.bubbleScroll(this.$refs.leftNavChev);
			globalFunctions.bubbleScroll(this.$refs.rightChev);

		//reposition right chevron if scrollbars exist
		var rightChevTop = window.innerHeight - jQuery(this.$refs.rightChev).outerHeight(true);
		jQuery(this.$refs.rightChev).css('right', this.extraVars.positionalVars.scrollWidth + 'px');
		if (jQuery("#app").attr('data-page') == 'home-page') {
			jQuery(this.$refs.rightChev).css('top', '0px');
		} else {
			jQuery(this.$refs.rightChev).css('top', rightChevTop + this.extraVars.positionalVars.scrollHeight + 'px');
		}
		
		//initial positioning of nav blobs
		this.$nextTick(() => {
			this.defBlobPos();
		});
		
	},
	beforeUpdate() {
	},
  destroyed () {
  }
		
}
	
</script>
