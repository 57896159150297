<template>
	<section class="flex_08 animateSection" 
					 :data-fcpCount="flexCount"
					 ref="thisSection">
		
		<div class="widthLimit anchorContainer"
				 :class="extraVars.flexiColor.content">
			<h3 class="titleAnchor doTitleLine"
					v-html="flexVar.title_anchor"
					:data-anchor="flexVar.title_anchor | anchorAttr"
					:data-subtitle="flexVar.subtitle_anchor">
			</h3>
			<div class="subtitle"
					 v-html="flexVar.subtitle">
			</div>
		</div>
		
		<div class="areaBelowTitle">
			<div class="image"
					 ref="image"
					 v-bind:style="{ 'background-image': 'url(' + flexVar.image.url + ')' }">
			</div>
			<div class="widthLimit contentContainer">
				<div class="content wysiwyg"
						 :class="extraVars.flexiColor.content">
					<div class="cta">
						<a href="mailto:info@ademic.co.uk?subject=Email%20Enquiry%20from%20Ademic%20Site"><h3>Email me</h3></a>
						<h5>or ping us a message in{{'\xa0'}}the{{'\xa0'}}form{{'\xa0'}}below:</h5>
					</div>
					
					<form method="post" enctype="multipart/form-data" class="ademicContactForm" 
								ref="ademicContactForm">
						<div class="inputContainer">
							<label for="inputName">Name</label>
							<input ref="inputName" v-model="inputName" id="inputName" name="inputName" placeholder="What do I call you?" type="text" required="required">
						</div>
						<div class="inputContainer">
							<label for="inputCompany">Company</label>
							<input ref="inputCompany" v-model="inputCompany" id="inputCompany" name="inputCompany" placeholder="Who do you work for?" type="text">
						</div>
						<div class="inputContainer">
							<label for="inputEmail">Email</label>
							<input ref="inputEmail" v-model="inputEmail" id="inputEmail" name="inputEmail" placeholder="How can I reach you?" type="email" required="required">
						</div>
						<div class="inputContainer">
							<label for="inputMessage">Message</label>
							<textarea ref="inputMessage" v-model="inputMessage" rows="1" id="inputMessage" name="inputMessage" placeholder="What is your enquiry?" type="text" required="required"></textarea>
						</div>
						<div class="feedbackMsg"
								 :class="feedbackMsgTrans"
								 v-html="feedbackMsg">
						</div>
						<div class="btnContainer"
								  ref="btnContainer">
							<a class="ademicButton"
								 :class="extraVars.flexiColor.content"
								 v-on:click="submitClick">
								<div class="mainBG"></div>
								<div class="hoverBG"></div>
								<div class="btnOuterLeftChev btnOuterChev"></div>
								<div class="btnOuterRightChev btnOuterChev"></div>
								<div class="btnTxt">HIT ME UP</div>
							</a>
						</div>
					</form>
					
				</div>
			</div>
			
		</div>
	</section>
</template>

<script>

import * as globalFunctions from '@/js/globalFunctions.js';
import * as wpEndpoints from '@/js/wpEndpoints.js';
	
export default {
  name: 'flex_08',
  props: {
		flexVar: Object,
		extraVars: Object,
		flexCount: Number,
  },
  data(){
    return {
			scrollThrottleTimer: undefined,
			parentFlexContainer: '',
			parallaxObjs: [],
			BGColor: '#2C2E3D',
			feedbackMsg: '',
			feedbackMsgArr: { 
				invalid: 'Please enter your name, a valid email and your message so I can get back&nbsp;to&nbsp;you.',
				error: 'I\'m so sorry, but there\'s been an error recording your details. Please try again later, or <a href="mailto:info@ademic.co.uk?subject=Email%20Enquiry%20from%20Ademic%20Site">email me</a> instead. Apologies&nbsp;once&nbsp;again.',
				success: 'Thanks for reaching out. I\'ll get back to you as&nbsp;soon&nbsp;as&nbsp;I&nbsp;can!',
			},
			feedbackMsgTrans: '',
			inputName: '',
			inputCompany: '',
			inputEmail: '',
			inputMessage: '',
			feedback: '',
			anchorAttr: '',
			sectionActive: false,
		}
	},
	computed: {
	},
	mounted(){	
		//set flexContainer to be used within scope of this component
		this.parentFlexContainer = globalFunctions.closestParent(this.$refs.thisSection, '.flexContainer');
	
		//add width height as attributes to image container
		globalFunctions.BGImageSize(this.$refs.image);
		
		//format anchor name to data attribute
//		this.anchorAttr = this.lowerCaseUnderscore(flexVar.title_anchor);
	},
  methods: {
    validEmail: function (email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
		
		addParallax () {
			var myImage = this.$refs.image;
			var midPoint = globalFunctions.parallaxStartStop(myImage).start + ( (globalFunctions.parallaxStartStop(myImage).end - globalFunctions.parallaxStartStop(myImage).start) / 2 );
			
			this.parallaxObjs[0] = {
				startScroll: globalFunctions.parallaxStartStop(myImage).start,
				stopScroll: midPoint,
				element: $(myImage),
				cssProp: '-webkit-filter',
				multi: true,
				subprops: [
					{
						subprop: 'grayscale',
						cssStart: 100,
						cssStop: 50,
						cssUnit: '%',
						updateStart: true,
						updateStop: true,
					},
					{
						subprop: 'blur',
						cssStart: 2,
						cssStop: 0,
						cssUnit: 'px',
						updateStart: true,
						updateStop: true,
					},
				]
			};
			this.parallaxObjs[1] = {
				startScroll: globalFunctions.parallaxStartStop(myImage).start,
				stopScroll: midPoint,
				element: $(myImage),
				cssProp: 'background-position-y',
				cssStart: 0,
				cssStop: 60,
				cssUnit: '%',
			};
			this.parallaxObjs[2] = {
				startScroll: globalFunctions.parallaxStartStop(myImage).start,
				stopScroll: midPoint,
				element: $(myImage),
				cssProp: 'background-position-x',
				cssStart: 0,
				cssStop: 100,
				cssUnit: '%',
			};
			
			//replace bg-size cover functionality by determining if it should be 100% width or height
			var parentRatio = $(myImage).outerWidth() / $(myImage).outerHeight(); 
			var BGRatio = $(myImage).attr('data-bgWidth') / $(myImage).attr('data-bgHeight');
			
			if (parentRatio <= BGRatio) {
				this.parallaxObjs[3] = {
					startScroll: globalFunctions.parallaxStartStop(myImage).start,
					stopScroll: midPoint,
					element: $(myImage),
					cssProp: 'background-size',
					cssStart: 115,
					cssStop: 100,
					cssUnit: '%',
					prefix: 'auto ',
				};
			} else {
				this.parallaxObjs[3] = {
					startScroll: globalFunctions.parallaxStartStop(myImage).start,
					stopScroll: midPoint,
					element: $(myImage),
					cssProp: 'background-size',
					cssStart: 115,
					cssStop: 100,
					cssUnit: '%',
					suffix: ' auto',
				};
			}
			
			globalFunctions.parallaxHandler(this.$refs.thisSection, this.parallaxObjs);
		},
		submitClick () {
			var myForm = $(this.$refs.ademicContactForm);
			//validate fields			
			if (inputName.value.length == 0 || 
				 	inputMessage.value.length == 0 || 
					!this.validEmail(inputEmail.value)) {
				this.feedbackMsgTrans = "bounceInLeft";
				this.feedbackMsg = this.feedbackMsgArr.invalid;
			} else {
				var datatopost = myForm.serializeArray();
				wpEndpoints.submit_contact_form(datatopost, this);
			}
		},
		removeMessage () {
			if ( this.feedbackMsgTrans == "bounceInLeft") {
				this.feedbackMsgTrans = "bounceOutRight";
			} 
		},
		lowerCaseUnderscore(txt){
			return txt.toUpperCase();
		},
		
		throttleScroll (newPos, oldPos, delay) {
			// If setTimeout is already scheduled, no need to do anything
			if (this.scrollThrottleTimer) {
				return;
			}

			// Schedule a setTimeout after delay seconds
			this.scrollThrottleTimer = setTimeout(() => {
				//add throttled code / function calls here

				//check if section in view
				this.sectionActive = globalFunctions.sectionActive(this.$refs.thisSection);
				//send prompt to root to update colors
				if(this.sectionActive) {
					this.$root.$emit('flexiColorEvent', {
						bg: this.BGColor,
						content: 'lightContent',
					});
				}
				
				this.scrollThrottleTimer = undefined;
			}, delay);
		},
		
	},

	watch: {
		'extraVars.flexScrollPos.pos': function(newPos, oldPos) {
			//add unthrottled scroll code here
			this.addParallax(); 
			
			//run throttled scroll code
			var delay = 500;
			this.throttleScroll(newPos, oldPos, delay);
		},
		
		//if any fields are changed, remove warning message if present
		inputEmail: {
			handler(val) {
				this.removeMessage();
			},
		},
		inputCompany: {
			handler(val) {
				this.removeMessage();
			},
		},
		inputName: {
			handler(val) {
				this.removeMessage();
			},
		},
		inputMessage: {
			handler(val) {
				this.removeMessage();
			},
		},
		
		//when form submission feedback is received
		feedback: {
			handler(val) {
				if (val.success) {
					//lock form inputs and hide submit button
					$(this.$refs.inputName).attr("disabled", true);
					$(this.$refs.inputCompany).attr("disabled", true);
					$(this.$refs.inputEmail).attr("disabled", true);
					$(this.$refs.inputMessage).attr("disabled", true);
					$(this.$refs.btnContainer).addClass("hide");
					this.feedbackMsg = this.feedbackMsgArr.success;
				} else {
					this.feedbackMsg = this.feedbackMsgArr.error;
				}
				this.feedbackMsgTrans = "bounceInLeft";
			},
			deep: true
		},
	},
  created () {
  },
  destroyed () {
  },
}
</script>

<style>	
</style>