<template>
	<section class="flex_09 animateSection" 
					 :data-fcpCount="flexCount"
					 ref="thisSection">
		<div class="widthLimit titleContainer"
				 :class="extraVars.flexiColor.content">
			<h3 class="title"
					v-html="flexVar.title">
			</h3>
			<div class="subtitle"
					 v-html="flexVar.subtitle">
			</div>
		</div>
		
	</section>
</template>

<script>

import * as globalFunctions from '@/js/globalFunctions.js';
	
export default {
  name: 'flex_09',
  props: {
		flexVar: Object,
		extraVars: Object,
		flexCount: Number,
  },
  data(){
    return {
			parentFlexContainer: '',
			parallaxObjs: [],
			BGColor: this.flexVar.background_color,
			scrollThrottleTimer: undefined,
		}
	},
	computed: {
	},
	mounted(){	
		//set flexContainer to be used within scope of this component
		this.parentFlexContainer = globalFunctions.closestParent(this.$refs.thisSection, '.flexContainer');

	},
  methods: {
		throttleScroll (newPos, oldPos, delay) {
			// If setTimeout is already scheduled, no need to do anything
			if (this.scrollThrottleTimer) {
				return;
			}

			// Schedule a setTimeout after delay seconds
			this.scrollThrottleTimer = setTimeout(() => {
				//add throttled code / function calls here
				
				this.scrollThrottleTimer = undefined;
			}, delay);
		},
		setFlexiColor: function () {
			//sets the initial color of the page
			this.$root.$emit('flexiColorEvent', {
				bg: this.BGColor,
				content: this.flexVar.dark_content ? 'darkContent' : 'lightContent',
			});
		},
	},
	watch: {
		'extraVars.flexScrollPos.pos': function(newPos, oldPos) {
			//add unthrottled scroll code here

			//run throttled scroll code
			var delay = 500;
			this.throttleScroll(newPos, oldPos, delay);
		},
		'extraVars.isTransitionClassActive':  {
			handler(val) {
				if (!val){
					// after transition, set initial page color
					this.setFlexiColor();
				}
			},
		},
	},
  created () {
  },
  destroyed () {
  }
}
</script>

<style>	
</style>